import {WithBindings, withLazyInitialization} from "@buildwithflux/shared";

import {defaultBindings} from "./bindings";
import type {ContainerBindings, FluxServices} from "./container";

/**
 * Create a new service container that uses the globally available FirebaseConnector to get access to the connections that
 * it needs to build services.
 */
export function createServiceContainer(overrideFactories: Partial<ContainerBindings> = {}): WithBindings<FluxServices> {
    return withLazyInitialization(
        {
            ...defaultBindings,
            ...overrideFactories,
        },
        undefined,
    );
}
