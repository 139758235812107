import Box from "@material-ui/core/Box";
import {makeStyles, createStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/InfoOutlined";

import R from "../../../../../resources/Namespace";

const useStyles = makeStyles(() =>
    createStyles({
        snackBarRoot: {
            display: "flex",
            flexDirection: "row",
            height: "104px",
            width: "450px",
            backgroundColor: "#F44336",
            borderRadius: "4px",
            padding: "8px 16px",
        },
        text: {
            display: "flex",
            flexDirection: "column",
            marginLeft: "8px",
        },
        body1: {
            marginBottom: "4px",
            fontWeight: 500,
        },
        retry: {
            marginTop: "-4px",
            marginLeft: "8px",
        },
    }),
);

const SavingErrorSnackbar = () => {
    const classes = useStyles();

    return (
        <Box className={`${classes.snackBarRoot}`}>
            <InfoIcon />

            <Box className={classes.text}>
                <Typography className={classes.body1} align="left" variant="body1">
                    {R.strings.app.cannot_save_documument_message}
                </Typography>
            </Box>
        </Box>
    );
};

// eslint-disable-next-line import/no-unused-modules
export default SavingErrorSnackbar;
