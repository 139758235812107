/* eslint-disable import/no-unused-modules */
import type {IEnvSwitcherEnvironmentConfiguration, IEnvSwitcherUserAccess} from "@buildwithflux/core";
import {getEnvironmentName, Unsubscriber} from "@buildwithflux/shared";

import {FeatureFlagsState, FeatureFlagsStore} from "../../feature_flags/FeatureFlagsStore";
import {AnalyticsStorage} from "../AnalyticsStorage";

export class EnvironmentSwitcher {
    private currentEnvironment: string | undefined;

    private featureUnsubscriber: Unsubscriber | undefined = undefined;

    private splitTestingAccess: IEnvSwitcherUserAccess | undefined;
    private splitTestingConfig: IEnvSwitcherEnvironmentConfiguration | undefined;

    constructor(
        private readonly analyticsStorage: AnalyticsStorage,
        private readonly featureFlagsStore: FeatureFlagsStore,
    ) {
        this.currentEnvironment = getEnvironmentName() === "production" ? "prod" : getEnvironmentName();
        this.initialize();
    }

    public initialize() {
        if (this.featureUnsubscriber) {
            this.shutdown();
        }

        this.featureUnsubscriber = this.featureFlagsStore.subscribe((state) => {
            this.updateFromFeatureStoreState(state);
        });

        this.updateFromFeatureStoreState(this.featureFlagsStore.getState());
    }

    public shutdown() {
        if (this.featureUnsubscriber) {
            this.featureUnsubscriber();
            this.featureUnsubscriber = undefined;
        }
    }

    public switchEnvironment(environment: string) {
        const toConfig = this.getEnvironmentConfig(environment);
        if (!toConfig || !window?.location) {
            return;
        }

        const {hostname, name} = toConfig;
        const protocol = name === "local" ? "http" : "https";
        const url = `${protocol}://${hostname}${window.location.pathname}${window.location.search}`;

        this.analyticsStorage.logEvent("account_menu", {
            action: "switch_environment",
            from_url: window.location.href,
            from_name: this.currentEnvironment,
            from_hostname: window.location.hostname,
            to_url: url,
            to_name: name,
            to_hostname: hostname,
        });

        window.location.assign(url);
    }

    public isSwitcherEnabled(): boolean {
        return this.isSwitcherEnabledInEnv() && this.canUserSwitchEnvironments();
    }

    public getUsersAllowedEnvironments() {
        return this.getEnvironmentDetails().access;
    }

    public getEnvironmentConfig(environment: string) {
        return this.splitTestingConfig?.environments.find((env) => env.name === environment);
    }

    public getCurrentEnvironmentName() {
        return this.currentEnvironment;
    }

    private updateFromFeatureStoreState(state: FeatureFlagsState) {
        this.splitTestingAccess = state.splitTestingAccess;
        this.splitTestingConfig = state.splitTestingConfig;
    }

    private isSupportedHostname(hostname?: string) {
        const supportedHostnames =
            this.splitTestingConfig?.environments // just in case an empty hostname is added to the config or cannot get feature flag (e.g., ad blockers)
                .filter((env) => env?.hostname)
                .map((env) => env.hostname) || [];
        return supportedHostnames.some((supportedHostname) => supportedHostname === hostname);
    }

    private isSupportedEnvironmentName(environmentName?: string) {
        return (
            // enable for localhost
            environmentName === "local" ||
            this.splitTestingConfig?.environments // just in case an empty env name is added to the config
                .filter((env) => env?.name)
                .some((env) => env.name === environmentName) ||
            // sometimes cannot get feature flag (e.g., ad blockers), so default to false
            false
        );
    }

    private isSwitcherEnabledInEnv() {
        return (
            this.isSupportedEnvironmentName(this.currentEnvironment) ||
            this.isSupportedHostname(window?.location?.hostname)
        );
    }

    private canUserSwitchEnvironments() {
        return (this.getEnvironmentDetails()?.access?.allowed?.length || 0) > 1;
    }

    private getEnvironmentDetails() {
        const userAccess = this.splitTestingAccess;
        const envConfig = this.currentEnvironment ? this.getEnvironmentConfig(this.currentEnvironment) : undefined;

        return {
            access: userAccess,
            envConfig,
        };
    }
}
