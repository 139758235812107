import Box from "@material-ui/core/Box";
import {Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";
import React, {ReactNode} from "react";
import {Helmet} from "react-helmet";

import awesome from "../../../../resources/assets/search/awesome.png";
import R from "../../../../resources/Namespace";
import PreRender from "../../../common/components/PreRender";
import NavBar from "../../../navigation_bar/NavBar";

const useStyles = makeStyles((theme: Theme) => ({
    "@global": {
        "#root": {
            minHeight: "100lvh",
            display: "flex",
            flexDirection: "column",
        },
    },
    background: {
        backgroundImage: `url(${awesome})`,
        backgroundPosition: "bottom",
        backgroundSize: "624px",
        [theme.breakpoints.down("xs")]: {
            backgroundSize: "524px",
        },
        backgroundRepeat: "no-repeat",
        flexGrow: 1,
    },
}));

interface IErrorPageProps {
    title: string | ReactNode;
    pageTitle: string;
    statusCode: number;

    subtitle?: string | ReactNode;
    children?: React.ReactNode;
}

/**
 * @see Error.tsx for similar
 */
export default function ErrorPage(props: IErrorPageProps) {
    const classes = useStyles();

    return (
        <>
            <NavBar />
            <Helmet>
                <title>{`${props.pageTitle} · ${R.strings.app.name}`}</title>
                <meta name="description" content={R.strings.app.description} />

                <meta property="og:title" content={`${props.pageTitle} · ${R.strings.app.name}`} />
                <meta property="og:description" content={R.strings.app.description} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content={R.strings.app.name_with_tld} />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content={R.strings.app.flux_twitter_handle} />
                <meta name="twitter:title" content={`${props.pageTitle} · ${R.strings.app.name}`} />
                <meta name="twitter:description" content={R.strings.app.description} />

                {/* https://docs.prerender.io/v1/docs/11-best-practices */}
                <meta name="prerender-status-code" content={props.statusCode.toString()} />
            </Helmet>
            <PreRender />
            <Box width="100%" display="flex" alignItems="center" justifyContent="center" className={classes.background}>
                <Box margin={"32px"}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {props.title}
                    </Typography>

                    {(props.subtitle || props.children) && (
                        <Typography variant="body1" component="p" gutterBottom>
                            {props.subtitle}
                            {props.children && props.children}
                        </Typography>
                    )}
                </Box>
            </Box>
        </>
    );
}
