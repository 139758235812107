import {
    isCloseTo,
    ToleranceCornerRadius,
    TraceCorners,
    EditorModes,
    PcbBakedNode,
    PcbNodeTypes,
} from "@buildwithflux/core";
import {reduce} from "lodash";

import type {IDrcInputs, IDrcValidator, IProblem} from "../types";

export class TraceCornerRadiusValidator implements IDrcValidator {
    problemTypeKey = "trace_corner_radius";
    problemLabel = "Trace Corner Radius";
    problemDescription = "Reports traces that are unable to receive their specified minimum corner radii.";

    checkForProblems(inputs: IDrcInputs) {
        const checkVertexViolation = (node: PcbBakedNode<PcbNodeTypes.routeSegment>, vertex: "start" | "end") => {
            const radius = TraceCorners.computeRadius(
                vertex,
                node,
                inputs.pcbLayoutNodes,
                inputs.pcbTracingGraph.getGraph(),
            );

            if (radius === undefined) {
                return false;
            }
            if (isCloseTo(radius, node.bakedRules.traceCornerRadiusMinimum, ToleranceCornerRadius)) {
                // Actual radius value is often close to traceCornerRadiusMinimum but due to numerical errors, a test such
                // as `radius < node.bakedRules.traceCornerRadiusMinimum` can return false positives. If the radius is
                // within a range of tolerance it's considered equal and a violation is not reported.
                return false;
            } else {
                // If we're here, then the values a sufficiently different so regular logic is okay to use
                return radius < node.bakedRules.traceCornerRadiusMinimum;
            }
        };

        const foundProblems = reduce(
            inputs.pcbLayoutNodes,
            (problems, node) => {
                if (node.type !== PcbNodeTypes.routeSegment) {
                    return problems;
                }

                if (checkVertexViolation(node, "start") || checkVertexViolation(node, "end")) {
                    problems.push({
                        problemTypeKey: this.problemTypeKey,
                        key: `${this.problemTypeKey}_${node.uid}`,
                        label: node.name,
                        affectedItems: [{type: "pcbLayoutNode" as const, uid: node.uid}],
                        affectedViews: [EditorModes.pcb],
                    });
                }

                return problems;
            },
            [] as IProblem[],
        );

        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems,
        };
    }
}
