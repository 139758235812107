import {
    AllOnboardingProjectSets,
    alwaysAvailableProjectSetNames,
    hardcodedOnboardingProjectSets,
    OnboardingProjectSet,
    OnboardingProjectSets,
} from "@buildwithflux/constants";
import {
    AutoLayoutApi,
    FallbackLegacyProPlanPrice,
    FallbackLegacyUltraPlanPrice,
    PlanPricing,
} from "@buildwithflux/models";

import {useFluxServices} from "../../injection/hooks";
import {getActiveServicesContainerBadlyAsServiceLocator} from "../../injection/singleton";
import {useEntitlement} from "../payments/state/plan";

import {FeatureFlagsState} from "./FeatureFlagsStore";

/**
 * Select a feature flag value from the store
 *
 * Note that you can access the useFeatureFlagsStore hook from the service container yourself - this is just a
 * convenience wrapper for historical places where we've used the hook without direct access to the service container.
 *
 * @deprecated Access the store from the container: useFluxServices().useFeatureFlagsStore(selector)
 */
export function useFeatureFlags<T>(selector: (state: FeatureFlagsState) => T): T;

/**
 * Gets the entire state of the feature flags store
 *
 * This function call, where a selector is not provided, provides backwards compatibility with the Zustand
 * store API. It is not recommended to use this function.
 *
 * @deprecated You should always provide a selector, but if you need to access the entire state non-reactively, use
 * .getState() on the store: useFluxServices().useFeatureFlagsStore().getState()
 */
export function useFeatureFlags(): FeatureFlagsState;
export function useFeatureFlags<T>(selector?: (state: FeatureFlagsState) => T) {
    const services = useFluxServices();
    if (!selector) {
        return services.useFeatureFlagsStore.getState();
    }
    return services.useFeatureFlagsStore(selector);
}

/**
 * This getState() function is attached to the hook to provide backwards compatibility with the Zustand
 * store API. It is not recommended to use this function, as it bypasses the reactivity of the Zustand store. You
 * really need reactivity when accessing feature flags, because there's a long period while initializing where you'll
 * get undefined defaults for every flag, while we wait for the LaunchDarkly client to respond.
 *
 * @deprecated To access this method in a non-deprecated way, and understanding the limitations of not having reactivity,
 * you can use the store directly from the service container: container.useFeatureFlagsStore.getState()
 */
useFeatureFlags.getState = (): FeatureFlagsState => {
    const {useFeatureFlagsStore} = getActiveServicesContainerBadlyAsServiceLocator();
    return useFeatureFlagsStore.getState();
};

export const selectLegacyProPlanPrice = (state: FeatureFlagsState): PlanPricing => {
    const value = PlanPricing.safeParse(state.segmentTestLegacyProPlanPrice);

    if (value.success) {
        return value.data;
    }

    return FallbackLegacyProPlanPrice;
};

export const selectLegacyUltraPlanPrice = (state: FeatureFlagsState): PlanPricing => {
    const value = PlanPricing.safeParse(state.segmentTestLegacyUltraPlan);

    if (value.success) {
        return value.data;
    }

    return FallbackLegacyUltraPlanPrice;
};

// Trivial function, used for fast toggling during dev
export const selectShowNewProjectDialog = (state: FeatureFlagsState): boolean => {
    return state.segmentNewProjectDialog;
};

// Trivial function, used for fast toggling during dev
export const selectEnableCopilot1500 = (state: FeatureFlagsState): boolean => {
    if (state.copilot1500 === "copilot1500") {
        return true;
    }
    return false;
};

export const selectAllOnboardingProjectSets = (state: FeatureFlagsState): OnboardingProjectSets => {
    const allSets = AllOnboardingProjectSets.safeParse(state.onboardingProjectSetsList);
    if (!allSets.success) {
        return hardcodedOnboardingProjectSets;
    } else {
        return allSets.data;
    }
};

export const selectOnboardingProjectSet = (state: FeatureFlagsState): OnboardingProjectSet => {
    const sets = selectAllOnboardingProjectSets(state);

    return sets[state.segmentOnboardingProjectSetName || alwaysAvailableProjectSetNames._default] || sets._default;
};

export const selectAutoLayoutVersion = (state: FeatureFlagsState) =>
    AutoLayoutApi.versionParse(state.pcbAutoLayoutVersion);

export function usePcbAutoLayoutEnabled(): boolean {
    const featureFlag = useFeatureFlags((state) => state.pcbAutoLayout ?? false);
    const entitlement = useEntitlement("pcb_auto_layout").value;

    return featureFlag || entitlement;
}
