import {
    CLIPPER_INT_SPACE_CONVERSION,
    ClipperShape,
    IPcbBoardLayerCopper,
    PcbBakedNode,
    PcbBoardLayer,
    PcbNodeTypes,
    filterTruthy,
    getTopLevelLayoutOfNode,
    netHasFillOnLayer,
    separateShapes,
} from "@buildwithflux/core";
import {get} from "lodash";
import {Euler, Vector3} from "three";

import {UpdateGraphInputs} from "./PcbConnectivityGraph";
import {eulerFromXYZ, vector3FromXYZ} from "../../helpers/ThreeJSHelper";

const zeroEuler = new Euler(0, 0, 0);

export function calcCopperFills(
    inputs: Pick<UpdateGraphInputs, "pcbLayoutNodes" | "shapesMap" | "clipperShapeFactory">,
) {
    const fillShapesByNetLayer = Object.fromEntries(
        filterTruthy(
            Object.values(inputs.pcbLayoutNodes).flatMap((fillNode) => {
                if (fillNode.type === PcbNodeTypes.fill) {
                    const netUid = fillNode.bakedRules.hostNetId ?? fillNode.parentUid;

                    const layerShapes = inputs.shapesMap[netUid];
                    if (!layerShapes) return null;

                    // QUESTION: how can we avoid getting the layoutNode here
                    // and just use the layerShapes data?
                    const layoutNode = getTopLevelLayoutOfNode(
                        inputs.pcbLayoutNodes,
                        fillNode.uid,
                    ) as PcbBakedNode<PcbNodeTypes.layout>;
                    if (!layoutNode) return null;

                    const netNode = inputs.pcbLayoutNodes[netUid];
                    if (!netNode || netNode.type !== PcbNodeTypes.net) return null;

                    // To make typescript happy with the predicate
                    const netHasFillOnLayerPredicate = (l: PcbBoardLayer): l is IPcbBoardLayerCopper =>
                        netHasFillOnLayer(netNode, l);
                    const layers = Object.values(layoutNode.bakedRules.stackup ?? {}).filter(
                        netHasFillOnLayerPredicate,
                    );
                    return layers.map((layer) => {
                        const polygons = get(layerShapes, [layoutNode.uid, layer.uid], []).filter(
                            (polygon) => !!polygon, // Needed since polygon can be unset, but not removed, from polygons array
                        );
                        const layerShape = inputs.clipperShapeFactory.shape(polygons);
                        return [`${netUid}:${layer.orientation ?? layer.name}`, layerShape];
                    });
                }

                return null;
            }),
        ),
    );

    return fillShapesByNetLayer;
}

export function getAllFillShapes(
    clipperShapeFactory: UpdateGraphInputs["clipperShapeFactory"],
    pcbLayoutNodes: UpdateGraphInputs["pcbLayoutNodes"],
    fillShapesByNetLayer: Record<string, ClipperShape>,
) {
    return Object.entries(fillShapesByNetLayer).flatMap(([key, shape]) => {
        const separatedShapes = separateShapes(shape, clipperShapeFactory);
        const [netUid, layer] = key.split(":");
        const fillNode = pcbLayoutNodes[`${netUid}.fill`] as PcbBakedNode<PcbNodeTypes.fill>;
        if (!fillNode) return [];
        const absolutePos = vector3FromXYZ(fillNode.bakedRules?.positionRelativeToDocument);
        const absoluteRot = eulerFromXYZ(fillNode.bakedRules?.rotationRelativeToDocument) ?? zeroEuler;
        if (!absolutePos) return [];

        return separatedShapes.map((shape, shapeI) => {
            const points = shape.paths[0]!.map((pp) =>
                new Vector3(pp.x / CLIPPER_INT_SPACE_CONVERSION, pp.y / CLIPPER_INT_SPACE_CONVERSION, 0)
                    .applyEuler(absoluteRot)
                    .add(absolutePos),
            );
            return {
                type: "fill" as const,
                uid: `${key}.fill-${shapeI}`,
                netUid: netUid!,
                shape,
                fillId: key,
                connectedLayers: [layer!],
                nodeUid: fillNode.uid,
                // Assume that [0] is the main path
                minX: Math.min(...points.map((pp) => pp.x)),
                minY: Math.min(...points.map((pp) => pp.y)),
                maxX: Math.max(...points.map((pp) => pp.x)),
                maxY: Math.max(...points.map((pp) => pp.y)),
            };
        });
    });
}
