import {EditorModes, filterTruthy} from "@buildwithflux/core";

// eslint-disable-next-line boundaries/element-types
import type {IDrcInputs, IDrcValidator, IProblem} from "../types";
import {calculateAirwires} from "../utils/calculateAirwires";

function pairValues(array: string[]): string[][] {
    const result: string[][] = [];
    for (let i = 0; i < array.length; i += 2) {
        result.push([array[i] as string, array[i + 1] as string]);
    }
    return result;
}

export class AirwiresValidator implements IDrcValidator {
    problemTypeKey = "airwires";
    problemLabel = "Airwires";
    problemDescription =
        "Reports terminals that are not connected properly. NOTE: Only connections to the center of pads are considered valid.";

    checkForProblems(inputs: IDrcInputs) {
        const netToConnectedComponents = inputs.pcbConnectivityGraph.getNetToConnectedComponents();
        if (!netToConnectedComponents) {
            // TODO: We should probably return a loading state here
            return {error: false as const, problemTypeKey: this.problemTypeKey, foundProblems: []};
        }

        // Our netMap gives us the info of the groups that should be connected together,
        // we use this info to overlap it with the connected components from the graph.
        const netWithProblems = Object.entries(netToConnectedComponents)
            // If we found a net that is part of multiple connected components, then it's disconnected!
            .filter((a) => a[1].length > 1);

        const connectedComponents = inputs.pcbConnectivityGraph.getConnectedComponents();
        if (!connectedComponents?.length) {
            return {error: false as const, problemTypeKey: this.problemTypeKey, foundProblems: []};
        }

        // calculate connected airwires for the whole PCB layout
        const {airwiresIds} = calculateAirwires(
            inputs.pcbLayoutNodes,
            inputs.netMap,
            inputs.pcbConnectivityGraph,
            connectedComponents,
        );

        // identify connected pin pairs
        const connectedPinPairs = pairValues(airwiresIds);
        const netWithConnectedPins = netWithProblems.map((np) => {
            const items = np[1].flatMap((a) => a);
            return {
                net: np[0],
                pinPairs: connectedPinPairs.filter(
                    (i) => (i[0] && items.includes(i?.[0])) || (i[1] && items.includes(i[1])),
                ),
            };
        });

        // create error object with the found problems
        return {
            error: false as const,
            problemTypeKey: this.problemTypeKey,
            foundProblems: netWithConnectedPins.reduce((problems, netWithConnectedPin) => {
                const {net, pinPairs} = netWithConnectedPin;
                pinPairs.forEach((pins) => {
                    problems.push({
                        problemTypeKey: this.problemTypeKey,
                        key: `${this.problemTypeKey}_${net}_${pins.join("+")}`,
                        affectedItems: filterTruthy(
                            pins.map((pin) => (pin ? {type: "pcbLayoutNode" as const, uid: pin} : null)),
                        ),
                        affectedViews: [EditorModes.pcb],
                    });
                });
                return problems;
            }, [] as IProblem[]),
        };
    }
}
