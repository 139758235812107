import {
    countPatchChanges,
    createActionRecord,
    InvalidActionRecordError,
    IUserData,
    patchFilter,
    UserPresenceStatus,
} from "@buildwithflux/core";
import {ClientFunctionsAdapter} from "@buildwithflux/firebase-functions-adapter";
import {IActionRecord, IDocumentData} from "@buildwithflux/models";
import {guid} from "@buildwithflux/shared";
import {diff} from "deep-diff";
import {debounce, keys, values} from "lodash";
import {AnyAction} from "redux";

import {FluxServices} from "../../../export";
import {currentAgentIsBot} from "../../../helpers/isBot";
import {addChangeHistoryRecords} from "../../../redux/reducers/changeHistory/actions";
import R from "../../../resources/Namespace";
import {FluxLogger} from "../connectors/LogConnector";
import {DocumentStorageHelper} from "../helpers/DocumentStorageHelper";

import {DeprecatedTrackingEvents} from "./DeprecatedTrackingEvents";

/**
 * @see createOriginActionRecord for the special way of creating an initial
 * action record without an action
 */
export function createActionRecordFromActionAndDocument(
    currentUser: IUserData,
    action: AnyAction,
    newDocumentState: IDocumentData,
): IActionRecord {
    if (!action.payload.shouldGenerateActionRecord) {
        throw new InvalidActionRecordError(
            `Action ${action.type} does not have shouldGenerateActionRecord true, so it should not be used to create an action record.`,
        );
    }
    if (!newDocumentState.updated_at) {
        throw new InvalidActionRecordError(`Document has empty updated_at field."`);
    }
    if (!currentUser) {
        throw new InvalidActionRecordError(
            `Cannot create an action record for action - no current user.  Action: ${JSON.stringify(action)} `,
        );
    }
    const latestPatch = newDocumentState.latestPatch;
    if (!latestPatch || countPatchChanges(latestPatch) === 0) {
        throw new InvalidActionRecordError('Action record diff with name "' + action.type + '" is empty."');
    }
    if (latestPatch.uid !== newDocumentState.latestActionRecordUid) {
        throw new InvalidActionRecordError(
            `Document latestPatch has a different ID ${latestPatch.uid} than the latestActionRecordUid ${newDocumentState.latestActionRecordUid}. Was the patch applied?`,
        );
    }
    return createActionRecord({
        changeData: {
            uid: latestPatch.uid,
            type: latestPatch.type,
            // HACK: filter out `selectedObjectUids` from patch paths... maybe
            // this should be handled by the subscriber? especially considering
            // that we have special code in change history to re-select objects
            // affected by a patch, lol!
            // TODO: FLUX-5058 move filter of `selectedObjectUids` from patch paths to subscriber
            forward: latestPatch.forward.filter(patchFilter),
            reverse: latestPatch.reverse.filter(patchFilter),
            previousUid: latestPatch.previousUid,
        },

        name: action.type,
        actingUserUid: currentUser?.uid,
        actingUserHandle: currentUser?.handle,
        timestamp: action.updatesDocumentTimestamp ? newDocumentState.updated_at : Date.now(),
        changedDocumentUid: newDocumentState.uid,
        actionUpdatesDocumentVersion: action.payload.updatesDocumentTimestamp,
    });
}

export function queueActionRecord(
    currentUser: IUserData,
    action: AnyAction,
    newDocumentState: IDocumentData,
    services: FluxServices,
) {
    const actionRecord = createActionRecordFromActionAndDocument(currentUser, action, newDocumentState);

    // NOTE: the write is done async but the data prep is still expensive
    void writeActionRecord(actionRecord, newDocumentState, services);

    return actionRecord.actionRecordUid;
}

async function writeActionRecord(
    actionRecord: IActionRecord,
    documentData: IDocumentData,
    services: FluxServices,
): Promise<void> {
    const {actionRecordRepository, analyticsStorage, functionsAdapter, reduxStoreService} = services;
    const store = reduxStoreService.getStore();

    try {
        await actionRecordRepository.save(actionRecord.actionDocumentUid, actionRecord);
    } catch (error) {
        if (error instanceof Error) {
            const newError = new Error(
                `Error saving action record ${actionRecord.actionName} ${actionRecord.actionDocumentUid}: ${error.message}`,
            );
            newError.cause = error;
            FluxLogger.captureError(newError);
        }
        return;
    }

    // Add action record to change history
    // QUESTION: why don't we just rely on
    // subscribeToNewActionRecordsForDocumentContext to update the local change
    // history? is it because we filter out the current clientId from that query?
    store.dispatch(
        addChangeHistoryRecords({
            [actionRecord.actionRecordUid]: actionRecord,
        }),
    );

    void writeUserPresenceDebounced(
        actionRecord.actionUserUid,
        actionRecord.actionDocumentUid,
        functionsAdapter,
        documentData,
    ).catch((error) => {
        // NOTE: we don't want an error here to stop autosave
        if (error instanceof Error) {
            error.message = `Error writing user presence for action record ${actionRecord.actionRecordUid}: ${error.message}`;
        }
        FluxLogger.captureError(error);
    });

    void analyticsStorage
        .logEvent(DeprecatedTrackingEvents.updateDocument, {
            content_type: "document",
            content_id: documentData.uid,
            action_type: actionRecord.actionName,
            document_uid: actionRecord.actionDocumentUid,
            document_name: documentData.name,
            document_description: documentData.description,
            document_archived: documentData.archived,
            document_owner_uid: documentData.owner_uid,
            document_slug: documentData.slug,
            document_copy_of_document_uid: documentData.copy_of_document_uid,
            document_belongs_to_part_uid: documentData.belongs_to_part_uid,
            document_active_users: keys(documentData.active_users).length,
            document_roles: keys(documentData.roles).length,
            document_elements_count: keys(documentData.elements).length,
            document_routes_count: keys(documentData.routes).length,
        })
        .catch((error) => {
            // NOTE: we don't want an error here to stop autosave
            if (error instanceof Error) {
                error.message = `Error logging event for action record ${actionRecord.actionRecordUid}: ${error.message}`;
            }
            FluxLogger.captureError(error);
        });
}

const writeUserPresenceDebounced = debounce(
    async (
        userUid: string,
        documentUid: string,
        functionsAdapter: ClientFunctionsAdapter,
        documentData: IDocumentData,
    ) => {
        if (!currentAgentIsBot) {
            await functionsAdapter.writeUserPresence({
                userUid: userUid,
                documentUid: documentUid,
                userStatus: UserPresenceStatus.engaging.toString(),
                timestamp: new Date().getTime(),
                userColor: DocumentStorageHelper.getUserColor(values(documentData.active_users ?? {})),
            });
        }
    },
    R.behaviors.storage.userPresence.writeDelay,
    // NOTE: it is important to have the leading and trailing options set to
    // true for best latency and recency
    {leading: true, trailing: true},
);

/**
 * Create the origin action record for a newly created document
 * @param newDocument - document newly created
 * @param actionName - name of the action record, please note that we need a description for this action record, stored in ActionDisplayString
 *
 * QUESTION: why do we even want origin action records? Currently
 * createDocument and forkDocument. They are filtered out from reverts.
 * And they still use the old patch format!
 *
 * @see createActionRecordFromActionAndDocument for the normal way to create action records
 *
 * QUESTION: instead of the document created_at timestamp, should we use the
 * document updated_at timestamp for consistency with createActionRecordFromActionAndDocument?
 */
export function createOriginActionRecord(
    newDocument: IDocumentData,
    actionName: string,
    useDocumentCreateTS: boolean,
): IActionRecord {
    return {
        actionRecordUid: guid(),
        actionPreviousUid: null,
        actionName,
        actionUserUid: newDocument.owner_uid,
        actionTimestamp: useDocumentCreateTS ? newDocument.created_at : new Date().getTime(),
        actionDocumentUid: newDocument.uid,
        actionChangeDiff: createDiffForOriginActionRecord(newDocument),
        actionUpdatesDocumentVersion: true,
    };
}

// QUESTION: shouldn't we update this to use the new action record immer-based patches?
function createDiffForOriginActionRecord(newDocument: IDocumentData): IActionRecord["actionChangeDiff"] {
    const changeSet = diff({}, newDocument);
    if (!changeSet)
        throw new InvalidActionRecordError(
            `Tried to create origin action record diff, but it was empty?  Document: ${JSON.stringify(newDocument)}`,
        );
    return changeSet;
}
