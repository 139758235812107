/* eslint-disable @typescript-eslint/member-ordering */
import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {getProcessed3dModelUrl, IAssetsMap} from "@buildwithflux/core";
import {
    IElementData,
    IPartData,
    IPartVersionData,
    IPropertiesMap,
    IPropertyData,
    SchematicPosition2,
} from "@buildwithflux/models";

import {roundFloatError} from "../../../helpers/float";
import {roundToDragPrecision} from "../../../helpers/roundToDragPrecision";
import ModuleIcon from "../../../resources/assets/module-icon.svg";
import {latestStableVersionName} from "../../../resources/constants/latestStableVersionName";
import {FileStorage, ImageSizes} from "../FileStorage.types";

export class PartStorageHelper {
    constructor(private readonly fileStorage: FileStorage) {}

    public getAbsolutePreviewUrl(partVersionData: Partial<IPartVersionData>, size: ImageSizes) {
        const relativeUrl = this.getPreviewUrlFromPartVersionData(partVersionData, undefined, undefined, size);

        return new URL(relativeUrl, window.location.origin).toString();
    }

    public getImagePreviewUrl(previewImage: string, size: ImageSizes) {
        if (previewImage.toLowerCase().endsWith(".svg")) {
            return this.fileStorage.getFileURL(previewImage);
        } else {
            return this.fileStorage.getProcessedImageUrl(previewImage, size, "contain");
        }
    }

    public getPreviewUrlFromPartVersionData(
        partVersionData: Partial<IPartVersionData>,
        elementProperties: IPropertiesMap = {},
        format: "image" | "model" = "image",
        size: ImageSizes,
    ) {
        if (format === "image") {
            if (partVersionData.preview_image) {
                return this.getImagePreviewUrl(partVersionData.preview_image, size);
            }
        } else {
            const model3dAsset = Object.values(partVersionData.assets || {}).find((asset) => asset.is3dModel);

            if (model3dAsset?.storageName) {
                return getProcessed3dModelUrl(model3dAsset?.storageName);
            } else {
                const propArray = Object.values(elementProperties);
                const packageCode = propArray.find(
                    (prop) => prop.name === defaultPropertyDefinitions.package_case_code!.label,
                )?.value as string;

                if (packageCode) {
                    return getProcessed3dModelUrl(`assets/pcb/standard_packages/${packageCode}.step`);
                }
            }

            if (partVersionData.preview_image) {
                return this.getImagePreviewUrl(partVersionData.preview_image, size);
            }
        }

        return ModuleIcon;
    }

    public static getPackageCode(elementData: IElementData) {
        return PartStorageHelper.getPackageCodeFromProperties(elementData.properties || {});
    }

    public static getPackageCodeFromProperties(properties: IPropertiesMap) {
        const propArray = Object.values(properties || {});
        return propArray.find((prop) => prop.name === defaultPropertyDefinitions.package_case_code!.label)
            ?.value as string;
    }

    public static getFootprintAsset(elementData: IElementData) {
        const assets = elementData.part_version_data_cache.assets || {};

        return PartStorageHelper.getFootprintAssetFromAssets(assets);
    }

    public static getFootprintAssetFromAssets(assets: IAssetsMap) {
        const kicadModuleRegex = /\.(?:kicad_)mod$/g;
        const footprintAsset = Object.values(assets).find((asset) =>
            asset.storageName.toLowerCase().match(kicadModuleRegex),
        );
        return footprintAsset?.storageName;
    }

    public static get3dModelFromAsset(elementData: IElementData) {
        const assets = elementData.part_version_data_cache.assets || {};

        return PartStorageHelper.get3DModelAssetFromAssets(assets);
    }

    public static get3DModelAssetFromAssets(assets: IAssetsMap) {
        const footprintAsset = Object.values(assets).find((asset) => asset.is3dModel);

        return footprintAsset?.storageName;
    }

    public static getPcbBoardShapeAssetsFromAssets(assets: IAssetsMap) {
        return Object.values(assets).filter((asset) => asset.isPcbBoardShape);
    }

    public static inheritPartProperties(partVersionData: IPartVersionData, elementData: IElementData) {
        Object.values(partVersionData.properties).forEach((partProperty: IPropertyData) => {
            const inheritedProperty = elementData.properties[partProperty.uid];
            const inheritedPropertyFromCache = elementData.part_version_data_cache.properties[partProperty.uid];
            if (inheritedProperty && inheritedPropertyFromCache) {
                inheritedProperty.name = partProperty.name;

                if (inheritedPropertyFromCache.order === inheritedProperty.order) {
                    inheritedProperty.order = partProperty.order;
                }
                if (inheritedPropertyFromCache.unit === inheritedProperty.unit) {
                    inheritedProperty.unit = partProperty.unit;
                }
                if (inheritedPropertyFromCache.value === inheritedProperty.value) {
                    inheritedProperty.value = partProperty.value;
                }
                if (
                    inheritedPropertyFromCache.customAutocompleteOptions === inheritedProperty.customAutocompleteOptions
                ) {
                    inheritedProperty.customAutocompleteOptions = partProperty.customAutocompleteOptions;
                }
            } else {
                elementData.properties[partProperty.uid] = partProperty;
            }
        });

        this.removeDeprecatedProperties(elementData, partVersionData);
    }

    public static getRequiredVersion(desiredVersion: string, part: IPartData) {
        if (part && desiredVersion === latestStableVersionName) {
            return part.latest_version || desiredVersion;
        }
        return desiredVersion;
    }

    // TODO: Move this to `ElementHelper.ts` file and add/move corresponding unit tests
    public static createSchematicPlacement(
        x: number,
        y: number,
        orientation = 0,
        flip = false,
        roundPosition = true,
    ): SchematicPosition2 {
        return {
            scene_cursor_position: true,
            x: roundPosition ? roundToDragPrecision(x) : roundFloatError(x),
            y: roundPosition ? roundToDragPrecision(y) : roundFloatError(y),
            orientation,
            flip,
        };
    }

    private static removeDeprecatedProperties(elementData: IElementData, partVersionData: IPartVersionData) {
        Object.values(elementData.part_version_data_cache.properties).forEach((partProperty: IPropertyData) => {
            if (!partVersionData.properties[partProperty.uid] && !elementData.properties[partProperty.uid]?.value) {
                delete elementData.properties[partProperty.uid];
            }
        });
    }
}
