import {DrcEnabledCheckers} from "@buildwithflux/core";

import {IDrcValidator} from "../types";

import {AirwiresValidator} from "./AirwiresValidator";
import {DeprecatedRulesValidator} from "./DeprecatedRulesValidator";
import {FloatingCopperValidator} from "./FloatingCopperValidator";
import {ImportantOverrideValidator} from "./ImportantOverrideValidator";
import {InvalidFillsValidator} from "./InvalidFillsValidator";
import {InvalidLayerValidator} from "./InvalidLayerValidator";
import {MissingFootprintsValidator} from "./MissingFootprintsValidator";
import {OverlappingCopperValidator} from "./OverlappingCopperValidator";
import {OverlappingTracesValidator} from "./OverlappingTracesValidator";
import {TraceCornerRadiusValidator} from "./TraceCornerRadiusValidator";

export function generateValidatorsFromFeatureFlag(drcEnabledCheckers: DrcEnabledCheckers | undefined): IDrcValidator[] {
    const {
        OverlappingCopperValidator: isOverlappingCopperValidatorEnabled = true,
        ImportantOverrideValidator: isImportantOverrideValidatorEnabled = true,
        OverlappingTracesValidator: isOverlappingTracesValidatorEnabled = false,
        DeprecatedRulesValidator: isDeprecatedRulesValidatorEnabled = true,
        AirwiresValidator: isAirwiresValidatorEnabled = true,
        FloatingCopperValidator: isFloatingCopperValidatorEnabled = true,
        MissingFootprintsValidator: isMissingFootprintsValidatorEnabled = true,
        InvalidLayerValidator: isInvalidLayerValidatorEnabled = true,
        InvalidFillsValidator: isInvalidFillsValidatorEnabled = true,
        TraceCornerRadiusValidator: isTraceCornerRadiusValidator = true,
    } = drcEnabledCheckers ?? {};

    const validators: (IDrcValidator | false | undefined)[] = [
        isOverlappingTracesValidatorEnabled ? new OverlappingTracesValidator() : undefined,
        isOverlappingCopperValidatorEnabled ? new OverlappingCopperValidator() : undefined,
        isMissingFootprintsValidatorEnabled ? new MissingFootprintsValidator() : undefined,
        isInvalidLayerValidatorEnabled ? new InvalidLayerValidator() : undefined,
        isImportantOverrideValidatorEnabled ? new ImportantOverrideValidator() : undefined,
        isAirwiresValidatorEnabled ? new AirwiresValidator() : undefined,
        isDeprecatedRulesValidatorEnabled ? new DeprecatedRulesValidator() : undefined,
        isInvalidFillsValidatorEnabled ? new InvalidFillsValidator() : undefined,
        isFloatingCopperValidatorEnabled ? new FloatingCopperValidator() : undefined,
        isTraceCornerRadiusValidator ? new TraceCornerRadiusValidator() : undefined,
    ];
    return validators.filter<IDrcValidator>((v): v is IDrcValidator => Boolean(v));
}
