import type {AutoLayoutJobUid} from "@buildwithflux/models";

export enum LocalAutoLayoutState {
    idle = "idle",
    waiting = "waiting",
    pausing = "pausing",
    paused = "paused",
    resuming = "resuming",
    running = "running",
    noCredits = "noCredits",
    complete = "complete",
    canceling = "canceling",
    canceled = "canceled",
    applying = "applying",
    applied = "applied",
    error = "error",
}

export type AutoLayoutStoreState = {
    state: LocalAutoLayoutState;
    jobUid: AutoLayoutJobUid | undefined;
    startTime: number | undefined;
    timeElapsed: string | undefined;
    usedCredits: number | undefined;
    errorMessage?: string;

    // fields to help figure out how much time we've spent in "Initializing" state
    startTimeForInitializingState: number;
    timeoutIntervalForInitializingState: NodeJS.Timeout | undefined;
    detectedSlowness: boolean;

    sliderPosition: number;
};

export type AutoLayoutStoreApi = {
    queue: () => void;
    pause: () => void;
    paused: () => void;
    resume: () => void;
    resumed: () => void;
    load: (startTime?: number) => void;
    noCredits: () => void;
    apply: () => void;
    applied: () => void;
    cancel: () => void;
    canceled: () => void;
    reset: () => void;
    complete: () => void;
    error: (errorMessage: string) => void;
    tick: (startTime?: number) => void;

    setJobUid: (jobUid: AutoLayoutJobUid | undefined) => void;
    setUsedCredits: (usedCredits: number) => void;
    unsubscribe: () => void;

    setSliderPosition: (position: number) => void;
};
