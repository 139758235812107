import {
    AnyPcbBakedNode,
    INetHashMap,
    PcbBakedNode,
    PcbNodeHelper,
    PcbNodeTypes,
    PcbNodesMap,
    pcbLayoutRootNodeId,
} from "@buildwithflux/core";
import {Object3D, Vector3} from "three";

import {PcbConnectivityGraph} from "../../connectivity/PcbConnectivityGraph";
import {FluxLogger} from "../../storage_engine/connectors/LogConnector";

// HACK: we have a dangerous while(true), so we constrain it to 10k iterations
// This means that the algorithm will fail with more than 5k unconnected components per net
const KRUSKAL_MAX_ITER = 5_000;

/**
 * Calculates a set of ids and line positions to display airwires on the canvas.
 *
 * Returns:
 * - airwiresPositions is a linear array with the positions of every line segment [xa,ya,za, xb,yb,zb, opacity]
 * - airwiresIds is a linear array with every start/end id [startId, endId]
 * - endpointsPerNet returns useful info about every possible endpoint, for calculating airwires live during routing
 */
export function calculateAirwires(
    pcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>,
    netMap: INetHashMap,
    graph: PcbConnectivityGraph,
    connectedComponents: readonly string[][],
    NodeToObject3DMap?: Map<string, Object3D>,
) {
    const endpointsPerNet = new Map<
        string,
        {id: string; pos: Vector3 | null; ccI: number; degree: number; noRouting: boolean | null}[]
    >();

    // We first augment the connected components with position
    const connectedComponentsWithInfo = connectedComponents.map((ids) => {
        const positions = ids.map((id) => {
            // We are using the nodeMesh to get the live position of the node on the scene, useful when live calc while dragging
            const nodeMesh = NodeToObject3DMap?.get(id);
            const node = pcbLayoutNodes[id];
            if (nodeMesh) {
                return nodeMesh.localToWorld(new Vector3());
            } else if (node && node?.type !== PcbNodeTypes.root) {
                // If the live position is not available, we fallback to the baked position
                return new Vector3(
                    node.bakedRules.positionRelativeToDocument.x ?? 0,
                    node.bakedRules.positionRelativeToDocument.y ?? 0,
                    0,
                );
            } else {
                return new Vector3();
            }
        });
        const noRoutings = ids.map((id) => {
            const attributes = graph.getNodeAttributes(id);
            if (!attributes) return null;
            if (attributes.type !== "fill") return attributes.noRouting;
            return null;
        });
        return {ids, positions, noRoutings};
    });

    // For each logical net, find which physical connected components it maps to in the pcb connectivity graph
    const connectedComponentsPerNet = Object.entries(netMap).map(([netId, terminalsMap]) => {
        const terminalIds = Object.keys(terminalsMap);
        return {
            netId,
            connectedComponents: connectedComponentsWithInfo.filter((cc) =>
                terminalIds.some((ti) => cc.ids.includes(ti)),
            ),
        };
    });

    // A net with more than 1 physical connected components is not connected properly, so it's an airwire
    // If a net is under a top-level footprint, we dont create airwire for it even theres more than 1 dis-connected components
    const connectedComponentsPerNetThatNeedAirwires = connectedComponentsPerNet.filter((cc) => {
        const topLevelFootprintUid = pcbLayoutNodes[cc.netId]?.bakedRules.topLevelFootprintUid;
        const isNetUnderTopLevelFootprint =
            topLevelFootprintUid &&
            pcbLayoutNodes[topLevelFootprintUid]?.type === PcbNodeTypes.footprint &&
            pcbLayoutNodes[topLevelFootprintUid]?.parentUid === pcbLayoutRootNodeId;
        return !isNetUnderTopLevelFootprint && cc.connectedComponents.length !== 1;
    });

    const airwiresIds: string[] = [];
    const airwiresPositions: number[] = [];

    // Airwires are calculated with a kruskal-like MST algorithm
    connectedComponentsPerNetThatNeedAirwires.forEach((netWithCC) => {
        const netNode = pcbLayoutNodes[netWithCC.netId] as PcbBakedNode<PcbNodeTypes.net> | undefined;

        // Used for airwires during routing
        const netEndpoints = netWithCC.connectedComponents
            .flatMap((cc, ccI) =>
                cc.ids.map((id, idI) => ({
                    id,
                    pos: cc.positions[idI]!,
                    ccI,
                    degree: 0,
                    noRouting: cc.noRoutings[idI]!,
                })),
            )
            .filter((n) => {
                const node = pcbLayoutNodes[n.id];
                return (
                    node && PcbNodeHelper.isCopperNodeType(node) && PcbNodeHelper.getHostNetId(node) === netNode?.uid
                );
            });

        endpointsPerNet.set(netWithCC.netId, netEndpoints);

        // Not enough point for airwires
        if (netEndpoints.length < 2) return;

        // We need to keep track which connected components are now connected to calculate an MST,
        // otherwise we could add the same edge twice and/or leave things disconnected
        // This is the magical "FIND-SET" they talk about in the magical computer science scrolls!
        const alreadyConnected = netWithCC.connectedComponents.map((_cc, i) => i);

        let iter = 0;
        while (iter < KRUSKAL_MAX_ITER) {
            // Exit condition: every component is in the same set => all the components are connected
            if (Math.min(...alreadyConnected) === Math.max(...alreadyConnected)) {
                break;
            }

            // We need to find the best point to connect our connected component to
            // AKA: we need to look into all the other points outside of it and find the closest one
            // TODO: O(N^2) algorithm, let's hope that N is small...
            let closestPointI_A = 0;
            let closestPointI_B = 0;
            let minDist = Infinity;
            for (let iA = 0; iA < netEndpoints.length; iA++) {
                const ACCSet = alreadyConnected[netEndpoints[iA]!.ccI]!;
                const AnoRouting = netEndpoints[iA]!.noRouting;
                // Some nodes are not routable to, so let's skip them
                if (AnoRouting) continue;

                for (let iB = 0; iB < netEndpoints.length; iB++) {
                    const BCCSet = alreadyConnected[netEndpoints[iB]!.ccI]!;
                    // Same node or same connected component are not useful
                    if (ACCSet === BCCSet) continue;

                    const BnoRouting = netEndpoints[iB]!.noRouting;
                    if (BnoRouting) continue;

                    const posA = netEndpoints[iA]!.pos;
                    const posB = netEndpoints[iB]!.pos;
                    if (!posA || !posB) continue;
                    const distance = posA.distanceTo(posB);
                    if (distance > 0 && distance < minDist) {
                        minDist = distance;
                        closestPointI_A = iA;
                        closestPointI_B = iB;
                    }
                }
            }
            const closestPointA = netEndpoints[closestPointI_A]!;
            const closestPointB = netEndpoints[closestPointI_B]!;
            const ACCI = closestPointA.ccI;
            const BCCI = closestPointB.ccI;
            const APosI = closestPointA.pos;
            const BPosI = closestPointB.pos;
            if (APosI && BPosI) {
                // Once we add the airwire we merge the disjoint set for the two airwires
                const oldCCSet = alreadyConnected[ACCI]!;
                const newCCSet = alreadyConnected[BCCI]!;
                for (let j = 0; j < alreadyConnected.length; j++) {
                    if (alreadyConnected[j] === oldCCSet) {
                        alreadyConnected[j] = newCCSet;
                    }
                }

                airwiresIds.push(closestPointA.id);
                airwiresIds.push(closestPointB.id);
                airwiresPositions.push(APosI.x); // x
                airwiresPositions.push(APosI.y); // y
                airwiresPositions.push(0); // foundMetadataZPos); // z
                airwiresPositions.push(BPosI.x); // x
                airwiresPositions.push(BPosI.y); // y
                airwiresPositions.push(0); //foundMetadataZPos); // z
                airwiresPositions.push(0.3); // opacity

                // Update the airwires endpoint graph degree, used to avoid disconnecting stuff later
                netEndpoints[closestPointI_A]!.degree += 1;
                netEndpoints[closestPointI_B]!.degree += 1;
            }

            iter++;
        }

        if (iter >= KRUSKAL_MAX_ITER) {
            const errorCode = "Out of iterations while calculating airwires";
            // eslint-disable-next-line no-console
            console.warn(errorCode);
            FluxLogger.captureError(new Error(errorCode));
        }

        // Somebody might argue that this algorithm is not actually Kruskal but instead Prim, since it always
        // grows towards the neighboring nodes. I have an explaination for why that is actually not true,
        // but this comment is not large enough to contain it.
    });

    return {airwiresIds, airwiresPositions, endpointsPerNet};
}
