import {
    PrimitiveRole,
    AnyPcbBakedNode,
    EditorModes,
    PcbNodeTypes,
    filterTruthy,
    isOverlappingApprox,
} from "@buildwithflux/core";

import type {IDrcInputs, IDrcValidator} from "../types";

// IMPORTANT: This algorithm works in 2D only and it only finds the collisions on the XY plane projection

function getNetForNode(node: AnyPcbBakedNode) {
    const nodeHasNet =
        node.type === PcbNodeTypes.pad || node.type === PcbNodeTypes.via || node.type === PcbNodeTypes.routeSegment;
    if (!nodeHasNet) return null;
    return node.bakedRules.hostNetId ?? null;
}

// TODO: put this in some common configuration
const polygonApproxSegments = 100;

export class OverlappingCopperValidator implements IDrcValidator {
    problemTypeKey = "overlapping_copper";
    problemLabel = "Overlapping Copper";
    problemDescription = "Reports copper elements that are shorting.";

    checkForProblems(inputs: IDrcInputs) {
        const pcbQuadTreeNodes = Array.from(inputs.nodeShapesMap.values());

        try {
            const collisions = filterTruthy(
                pcbQuadTreeNodes.map((rsA) => {
                    const nodeA = inputs.pcbLayoutNodes[rsA.nodeUid];
                    if (!nodeA) return null;

                    const layoutId = nodeA.bakedRules.topLevelLayoutUid;
                    if (!layoutId) return null;

                    // This "container" has a node quadTree per layout
                    const container = inputs.containerMap.get(layoutId);
                    if (!container) return null;
                    const tree = container.quadTree;

                    return {
                        node: nodeA,
                        collidesWith: tree.search(rsA).filter((rsB) => {
                            const nodeB = inputs.pcbLayoutNodes[rsB.nodeUid];
                            if (!nodeB) return false;

                            // A collision with itself is not a collision
                            const isSameNode = nodeA.uid === nodeB.uid;
                            if (isSameNode) return false;

                            const netA = getNetForNode(nodeA);
                            const netB = getNetForNode(nodeB);
                            const isSameNet = netA === netB;
                            if (isSameNet) return false;

                            const copperShapesA = rsA.primitives.filter((shape) => shape.role === PrimitiveRole.copper);
                            const copperShapesB = rsB.primitives.filter((shape) => shape.role === PrimitiveRole.copper);

                            for (const rsACopperShape of copperShapesA) {
                                for (const rsBCopperShape of copperShapesB) {
                                    if (
                                        rsACopperShape.layerId === rsBCopperShape.layerId &&
                                        isOverlappingApprox(
                                            rsACopperShape,
                                            rsBCopperShape,
                                            inputs.clipperShapeFactory,
                                            polygonApproxSegments,
                                        )
                                    ) {
                                        return true;
                                    }
                                }
                            }

                            return false;
                        }),
                    };
                }),
            ).filter((coll) => coll.collidesWith.length !== 0);

            // We eliminate the duplicates this way, since every collision between A and B will produce a pair of AB/BA
            const collisionKeys = Array.from(
                new Set(
                    collisions.flatMap((col) =>
                        col.collidesWith.map((colWith) => [col.node.uid, colWith.nodeUid].sort().join("+")),
                    ),
                ),
            );

            return {
                error: false as const,
                problemTypeKey: this.problemTypeKey,
                foundProblems: collisionKeys.map((ck) => ({
                    problemTypeKey: this.problemTypeKey,
                    key: `${this.problemTypeKey}_${ck}`,
                    affectedItems: ck.split("+").map((uid) => ({type: "pcbLayoutNode" as const, uid})),
                    affectedViews: [EditorModes.pcb],
                })),
            };
        } catch (e) {
            return {
                error: "cannot compute overlaps for this PCB",
                problemTypeKey: this.problemTypeKey,
                foundProblems: [],
            };
        }
    }
}
