import {
    DEFAULT_SYMBOL_NAME,
    getManufacturerPartNumberProperty,
    getPreferredDistributorsFromProperties,
    getPropertyValue,
    partPropertyCategories,
    getDatasheetProperty,
} from "@buildwithflux/core";

import {PartStorageHelper} from "../../../../modules/storage_engine/helpers/PartStorageHelper";
import type {IApplicationState} from "../../../../state";
import {getPartType, IElementsMap} from "@buildwithflux/models";
import {defaultPropertyDefinitions} from "@buildwithflux/constants";
import {uniq} from "lodash";
import {createSelector} from "reselect";

const selectDocumentPackageCode = (state: IApplicationState) => {
    return PartStorageHelper.getPackageCodeFromProperties(state.document?.properties || {});
};
const selectDocumentManufacturerPartNumber = (state: IApplicationState) => {
    return getManufacturerPartNumberProperty(state.document?.properties || {});
};
const selectPreferredDistributors = (state: IApplicationState) => {
    return getPreferredDistributorsFromProperties(state.document?.properties || {});
};
const selectSymbolDesignatorSuffixes = createSelector(
    (state: IApplicationState) => state.document?.elements ?? {},
    (elements: IElementsMap) => {
        const symbolNames = Object.values(elements ?? {})
            .filter((e) => getPartType(e.part_version_data_cache) === partPropertyCategories.terminal)
            .map((e) =>
                getPropertyValue(
                    defaultPropertyDefinitions.subsymbol_designator_suffix.label,
                    e.properties,
                )?.toString(),
            );
        // `||` below to filter out `undefined` and null and empty string.
        return uniq(symbolNames.map((n) => n || DEFAULT_SYMBOL_NAME));
    },
);
const selectDatasheetUrl = (state: IApplicationState) => {
    return getDatasheetProperty(state.document?.properties || {});
};

// @todo - we are selecting a whole object here, should optimize these selectors later
// Please note that if we are returning the entire object, it makes no difference to memoize it
const selectProperties = (state: IApplicationState) => state.document?.properties;

const propertiesSelectors = {
    selectProperties,
    selectDocumentPackageCode,
    selectDocumentManufacturerPartNumber,
    selectPreferredDistributors,
    selectSymbolDesignatorSuffixes,
    selectDatasheetUrl,
};

export default propertiesSelectors;
