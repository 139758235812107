import {featureFlags, FeatureFlagValues} from "@buildwithflux/core";
import {areWeTestingWithJest, isDevEnv, Unsubscriber} from "@buildwithflux/shared";
import {produce} from "immer";
import {mapValues} from "lodash";
import {StoreApi, UseBoundStore} from "zustand";
import {devtools} from "zustand/middleware";
import {immer} from "zustand/middleware/immer";
import {createStore} from "zustand/vanilla";

import {createBoundUseStoreHook} from "../../helpers/zustand";

import {AbstractLaunchDarklyService, FlagSet} from "./LaunchDarklyService";

export type FeatureFlagsState = {
    sourceUnsubscriber: Unsubscriber;
} & FeatureFlagValues;

export type FeatureFlagsStore = StoreApi<FeatureFlagsState>;
export type UseFeatureFlagsStore = UseBoundStore<FeatureFlagsStore>;

function getFluxFeatureFlagName(remoteName: string): keyof FeatureFlagValues | undefined {
    const result = Object.entries(featureFlags).find(([_fluxName, ldName]) => ldName === remoteName)?.[0];
    // NOTE: This is a safe cast, the compiler just doesn't know it because of the type of Object.entries
    return result as keyof FeatureFlagValues | undefined;
}

export function mergeFlags(flags: FlagSet, currentState: FeatureFlagsState): FeatureFlagsState {
    if (!flags) return currentState;
    return produce(currentState, (draftState) => {
        for (const [flagName, flagValue] of Object.entries(flags)) {
            const fluxName = getFluxFeatureFlagName(flagName);
            if (!fluxName) {
                // This is because this feature flag is not used from the frontend - this feature flag can be safely
                // ignored
            } else {
                // Some how we need to do a cast here even Typescript correctly inferred that `fluxName` is
                // of type "keyof FeatureFlagValues"
                (draftState as any)[fluxName] = flagValue;
            }
        }
    });
}

function emptyFlags(): Partial<FeatureFlagValues> {
    return mapValues(featureFlags, (_o) => undefined);
}

export function getInitialFlagValues(featureFlags: AbstractLaunchDarklyService): FeatureFlagValues {
    const allFlags = featureFlags.allFlags();
    const result = emptyFlags();
    if (allFlags != null) {
        for (const [flagName, flagValue] of Object.entries(allFlags)) {
            const fluxName: keyof FeatureFlagValues | undefined = getFluxFeatureFlagName(flagName);
            if (fluxName) {
                result[fluxName] = flagValue as undefined;
            }
        }
    }

    // TODO: This is NOT a safe cast: we are initializing every flag as undefined, including ones we type as an object!
    return result as FeatureFlagValues;
}

export function createFeatureFlagsStore(launchDarklyService: AbstractLaunchDarklyService) {
    return createStore<FeatureFlagsState>()(
        devtools(
            immer((set, get) => {
                const sourceUnsubscriber = launchDarklyService.subscribeToFlags((flags) =>
                    set(mergeFlags(flags, get()), false, "updateFromLaunchDarkly"),
                );

                const initialFlagValues = getInitialFlagValues(launchDarklyService);

                return {
                    sourceUnsubscriber,
                    ...initialFlagValues,
                };
            }),
            {enabled: isDevEnv() && !areWeTestingWithJest(), name: "FeatureFlagsStore"},
        ),
    );
}

export function createFeatureFlagsStoreHook(featureFlagsService: AbstractLaunchDarklyService): UseFeatureFlagsStore {
    return createBoundUseStoreHook(createFeatureFlagsStore(featureFlagsService));
}
