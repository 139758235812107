/**
 * React hooks for injecting FluxServices into components.
 */
import {createContext, useContext} from "react";

import {type FluxServices} from "./container";

/**
 * React context that exposes FluxServices.
 * @remarks The value is undefined by default to avoid module-level side-effects.  Always use this with a Producer!
 */
export const FluxServicesContext = createContext<FluxServices | undefined>(undefined);

/**
 * React hook that exposes flux services to components.
 */
export function useFluxServices(): FluxServices {
    const context = useContext(FluxServicesContext);

    if (context === undefined) {
        throw new Error(
            "Cannot use services context without value via provider: are you rendering in a subtree that contains a FluxServicesContext.Provider?",
        );
    }

    return context;
}
