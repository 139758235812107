import {DocumentSnapshotDescriptor, getMatchingCollectionChangesFromPatch} from "@buildwithflux/core";
import {isSystemPart} from "@buildwithflux/models";
import {areWebWorkersSupported, areWeInStorybook, isDevEnv} from "@buildwithflux/shared";
import {isAnyOf} from "@reduxjs/toolkit";
import {compact, concat} from "lodash";

import {FluxServices} from "../../../export";
import {applyPcbLayoutEnginePatches} from "../../../modules/pcb_layout_engine/pcbLayoutEngineInMain";
import {FluxLogger} from "../../../modules/storage_engine/connectors/LogConnector";
import {
    applyActionRecords,
    deleteSubDocumentData,
    mergeSubDocumentData,
    revertActionRecords,
} from "../../../redux/reducers/document/actions";
import {isDocumentReduxAction} from "../../../redux/reducers/document/actions.types";
import DocumentPatchManager from "../../../redux/reducers/document/DocumentPatchManager";
import {AppThunkAction} from "../../../store";
import {bakeNodesFromLatestPatchWithRetry} from "../bakeNodesFromLatestPatch";
import {enqueueSyncErrorNotification} from "../helpers";

import {PatchHandlerSideEffect, PatchHandlerSideEffectWithServices} from "./types";

/**
 * Runtime safety check to go with makeGenerateActionRecordsEpic
 * TODO: this is still being triggered by some actions like setPartOwners and
 * removeNotification that should not be affecting document state. Need to
 * investigate. See
 * https://buildwithflux.sentry.io/issues/?project=5669122&query=is%3Aunresolved+issue.priority%3A%5Bhigh%2C+medium%5D+patchHandlerEpicSideEffects.ts&referrer=issue-list&statsPeriod=90d
 */
export const runtimeChecks: PatchHandlerSideEffect = ({action, patch}) => {
    const paths = patch.forward.flat().map((p) => p.path);
    if (isDocumentReduxAction(action) && paths.length) {
        if (!("shouldGenerateActionRecord" in action.payload)) {
            FluxLogger.captureError(
                new Error(
                    `Action ${action.type} resulted in a change in the document without specifiying shouldGenerateActionRecord from paths: ${paths}`,
                ),
            );
        }
        if (!("updatesDocumentTimestamp" in action.payload)) {
            FluxLogger.captureError(
                new Error(
                    `Action ${action.type} resulted in a change in the document without specifiying updatesDocumentTimestamp from paths: ${paths}`,
                ),
            );
        }
        if (!("canUndo" in action.payload)) {
            FluxLogger.captureError(
                new Error(
                    `Action ${action.type} resulted in a change in the document without specifiying canUndo from paths: ${paths}`,
                ),
            );
        }
    }
};

/**
 * Add the forward and reverse patches to the undo and redo stacks
 * We only do so when:
 * 1. The action is undoable - with `canUndo` flag
 * The action is NOT from remote user - we dont wanna undo any changes from other users
 */
export const processUndoRedo =
    (documentPatchManager: typeof DocumentPatchManager): PatchHandlerSideEffect =>
    ({action, patch}) => {
        if (
            isDocumentReduxAction(action) &&
            action.payload.canUndo &&
            !isAnyOf(applyActionRecords, revertActionRecords)(action)
        ) {
            // QUESTION: how do we want to handle errors from onNewUndoableAction?
            documentPatchManager.onNewUndoableAction(action, patch.forward, patch.reverse);
        }
    };

/**
 * Patch web workers state with the latest patch.
 * NOTE: this needs to happen before baking!
 */
export const syncPatchesWithWebWorkers: PatchHandlerSideEffectWithServices =
    (services: FluxServices) =>
    ({patch}) => {
        // NOTE: web workers do not exist in jest
        if (!areWebWorkersSupported()) return;

        const {userCodeRuntimeWrapper, reduxStoreService, useFeatureFlagsStore} = services;
        applyPcbLayoutEnginePatches(patch.forward).catch((error) => {
            const message = "Error syncing PCB data!";
            FluxLogger.captureError(error);
            reduxStoreService
                .getStore()
                .dispatch(
                    enqueueSyncErrorNotification(message, useFeatureFlagsStore.getState().showSyncErrors ?? true),
                );
        });

        // HACK: the userCodeRuntime is only erroring in storybook, and I can't forsee any need for it
        if (areWeInStorybook()) {
            // eslint-disable-next-line no-console
            console.warn("Ignoring update of user code runtime while in storybook");
            return;
        }

        userCodeRuntimeWrapper
            .init()
            .then((simulator) => simulator.applyPatches(patch))
            // Any error should be captured by the user code runtime, which will handle updating the error state
            .catch((error) => FluxLogger.captureError(error));
    };

/**
 * Handle changes that require rebaking PCB nodes
 */
export const bakePcbNodes: PatchHandlerSideEffectWithServices =
    (services: FluxServices) =>
    ({action, document}) => {
        const {reduxStoreService, useFeatureFlagsStore} = services;
        // NOTE: Baking is called specially in the doc loader epic
        // AND `setDocumentData` action is already excluded
        // from withHistoryUpdate in the document reducer
        return bakeNodesFromLatestPatchWithRetry(
            action,
            document,
            services.pcbBakedGoodsManager,
            services.documentService,
        ).catch((error) => {
            const message = "Error generating PCB data!";
            if (error instanceof Error) {
                error.message = action.type + ": " + message + " " + error.message;
            }
            FluxLogger.captureError(error);
            reduxStoreService
                .getStore()
                .dispatch(
                    enqueueSyncErrorNotification(message, useFeatureFlagsStore.getState().showSyncErrors ?? true),
                );
        });
    };

/**
 * Refetches sub-document data when the part version data cache of an element
 * changes, which is expected to happen when:
 *
 * - A part version is updated in a project
 * - A new element is added to the canvas
 * - An element is removed from the canvas
 *
 * NOTE: We assume that changes to elements also catches changes to other
 * subDocumentData collections such as routeVertices.
 */
export const maybeRefetchSubDocumentData: PatchHandlerSideEffectWithServices =
    (services: FluxServices) =>
    ({document, patch}) => {
        // QUESTION: this is hacky access to services... can't we do better?
        const {reduxStoreService} = services;
        const store = reduxStoreService.getStore();

        const {subDocumentData} = document;

        if (!patch) {
            throw new Error("Missing patch");
        }
        if (!subDocumentData) {
            throw new Error("Missing subDocumentData");
        }
        const {elements: elementsChanged} = getMatchingCollectionChangesFromPatch(
            patch,
            "elements",
            undefined, // key line
            "forward",
        );
        const {elements: elementsHavingPartVersionChanged} = getMatchingCollectionChangesFromPatch(
            patch,
            "elements",
            "part_version_data_cache", // key line
            "forward",
        );
        if (!elementsChanged && !elementsHavingPartVersionChanged) {
            return; // common case
        }

        // First, delete subDocumentData for elements that were removed
        const elementUids = Object.entries(elementsChanged ?? {})
            .filter(([_elementUid, changeOp]) => changeOp === "remove")
            .map(([elementUid, _changeOp]) => elementUid);
        if (elementUids.length) {
            // HACK: use timeout here to queue the action after the current dispatch
            // TODO: figure out if dispatch from rxjs is ok at all or if we need to
            // return the action to the stream
            setTimeout(() => store.dispatch(deleteSubDocumentData(elementUids)), 0);
        }

        // Next, fetch subDocumentData for elements that were added or had their
        // part version replaced
        const elementsAdded = Object.entries(elementsChanged ?? {}).filter(
            ([_elementUid, changeOp]) => changeOp === "add",
        );
        const elementsHavingPartVersionReplaced = Object.entries(elementsHavingPartVersionChanged || {}).filter(
            ([_elementUid, changeOp]) => changeOp === "replace",
        );
        const descriptors = concat(elementsAdded, elementsHavingPartVersionReplaced).map(([elementUid, changeOp]) => {
            const element = document.elements[elementUid];
            if (!element) {
                throw new Error(`Element ${elementUid} from patch not found in document`);
            }
            if (
                isDevEnv() &&
                changeOp === "replace" &&
                !Object.keys(subDocumentData.elements).some((key) => key.startsWith(element.uid))
            ) {
                throw new Error(`subDocumentData for element being replaced ${element.uid} not found`);
            }
            const {document_import_uid, version, part_uid: partUid} = element.part_version_data_cache;
            // NOTE: some system parts still do not have backing documents... these
            // will returned undefined in refetchSubDocumentData below and don't
            // have sublayouts anyway
            // TODO: create documents for remaining system parts that do not have them
            if (!isSystemPart(partUid) && !document_import_uid) {
                throw new Error(`Missing document_import_uid for element ${element.uid}`);
            }
            return {
                documentUid: document_import_uid!,
                documentVersion: version,
                // NOTE: important that this value is synced with
                // StaticPartVersionSerde or any place that generates snapshots
                schemaTag: "v0",
                partUid,
                elementUid,
            };
        });

        if (descriptors.length) {
            store.dispatch(refetchSubDocumentData(descriptors));
        }

        return;
    };

/**
 * Re-fetch sub-trees of the global subDocumentData for the given elements
 * according to the given snapshot version descriptors.
 *
 * NOTE: This could be very fast depending on what snapshots are already cached
 * in memory.
 *
 * NOTE: This function will only log an error if a snapshot is not found, in
 * which case there will be stale data.
 */
const refetchSubDocumentData = (
    descriptors: (DocumentSnapshotDescriptor & {partUid: string; elementUid: string})[],
): AppThunkAction<Promise<void>> => {
    return async (dispatch, _getState, services) => {
        const {snapshotLibrary, partStorage, documentStorage, logger} = services;
        // Get the snapshot for each element.
        const snapshots = await Promise.all(
            descriptors.map(async (descriptor) => {
                try {
                    let snapshot = await snapshotLibrary.get(descriptor);
                    if (!snapshot) {
                        // NOTE: this logic mirrors DocumentStorage.getFallbackSnapshot
                        logger.warn(
                            `Document snapshot not found, fetching latest version: ${JSON.stringify(descriptor)}`,
                        );
                        const part = await partStorage.getPartByUid(descriptor.partUid);
                        if (!part) {
                            logger.error(`Document snapshot not found, part not found: ${JSON.stringify(descriptor)}`);
                            return undefined;
                        }
                        snapshot = await snapshotLibrary.get({
                            ...descriptor,
                            documentVersion: part.latest_version,
                        });
                        if (!snapshot) {
                            if (!isSystemPart(descriptor.partUid)) {
                                logger.error(
                                    `Document snapshot not found, latest version not found: ${JSON.stringify(
                                        descriptor,
                                    )}`,
                                );
                            }
                            return undefined;
                        }
                    }
                    return [descriptor.elementUid, snapshot] as const;
                } catch (error) {
                    logger.error(`Error refetching document snapshot: ${JSON.stringify(descriptor)}`, error);
                    return undefined;
                }
            }),
        );
        // Get the subDocumentData for each snapshot (which also includes the data from the given snapshot)
        const subDocumentDatas = await Promise.all(
            compact(snapshots).map(([elementUid, snapshot]) =>
                documentStorage.getAllSubDocumentData(snapshot, elementUid),
            ),
        );
        // Update the subDocumentData in the global store
        dispatch(mergeSubDocumentData(subDocumentDatas));
    };
};
