import {
    AbstractFluxLogger,
    AsyncClipperShapeFactory,
    getNodesOfType,
    PcbPrimitiveStore,
    PcbTracingGraph,
} from "@buildwithflux/core";
import {
    AnyPcbNode,
    DocumentChange,
    DocumentSubCollectionChange,
    FieldChangeType,
    INetHashMap,
    PcbNodeTypes,
    PcbNodesMap,
    PcbRuleSet,
    PcbRuleSetsMap,
} from "@buildwithflux/models";
import {Logger} from "@buildwithflux/shared";
import {DocumentService} from "@buildwithflux/solder-core";
import {isEmpty} from "lodash";

import {ReduxStoreService} from "../../../../export";
import {PcbConnectivityGraph, UpdateGraphInputs} from "../../../connectivity/PcbConnectivityGraph";
import {IDrcInputs} from "../../../drc_validator/types";
import {generateValidatorsFromFeatureFlag} from "../../../drc_validator/validators/helpers";
import {FeatureFlagsStore} from "../../../feature_flags/FeatureFlagsStore";
import {useProblemsStore} from "../ProblemsStore";

type DocumentChangePayload = Required<
    Pick<DocumentSubCollectionChange["payload"], "pcbLayoutNodes" | "pcbLayoutRuleSets">
>;

type ChangeOperation = FieldChangeType | "set";

/**
 * Mutates the document change to include the uids of neighbors of the removed nodes, in the update portion of the document change payload
 */
function mutateDocumentChangeIncludeUpdateNeighbors(
    neighborsOfRemovedNodes: string[],
    documentChange: DocumentSubCollectionChange,
) {
    if (neighborsOfRemovedNodes.length === 0) {
        return documentChange;
    }

    const pcbLayoutNodes = documentChange.payload.pcbLayoutNodes;
    if (!pcbLayoutNodes) {
        documentChange.payload.pcbLayoutNodes = {replace: neighborsOfRemovedNodes};
        return;
    }

    if (pcbLayoutNodes.replace) {
        pcbLayoutNodes.replace.push(...neighborsOfRemovedNodes);
    }

    pcbLayoutNodes.replace = [...neighborsOfRemovedNodes];
}

/**
 * This class is responsible for managing the DRC recalculation process.
 *
 * The idea of this class is to club/collect all changes associated to a baking operation
 * (e.g. methods in PcbBakedGoodsManager) and then send them to the DRC service for recalculation.
 *
 * The `recalculate` method should be called only once per operation once all changes have been collected.
 */
export class DrcRecalculator {
    private payload: DocumentChangePayload = {
        pcbLayoutNodes: {},
        pcbLayoutRuleSets: {},
    };

    // WARNING: This causes a side effect that invalidates some store states, so consider this as a (bad) transaction wrapper
    constructor(
        private readonly pcbPrimitiveStore: PcbPrimitiveStore,
        private readonly pcbConnectivityGraph: PcbConnectivityGraph,
        private readonly pcbTracingGraph: PcbTracingGraph,
        private readonly reduxStoreService: ReduxStoreService,
        private readonly documentService: DocumentService,
        private readonly featureFlagsStore: FeatureFlagsStore,
        private readonly logger: AbstractFluxLogger | Logger,
    ) {
        pcbConnectivityGraph.invalidateGraph();
        pcbTracingGraph.invalidateGraph();
    }

    /**
     * Add a change (addition, removal, etc.) to the payload for nodes
     * @param operation - The operation to be performed on the nodes (add, remove, replace, set)
     * @param nodes - The nodes to be changed (can be a map of nodes or an array of nodes)
     */
    public addNodesChange(operation: ChangeOperation, nodes: PcbNodesMap<AnyPcbNode> | AnyPcbNode[]) {
        const nodesPayload = this.payload.pcbLayoutNodes;
        const nodeUids = Array.isArray(nodes) ? nodes.map((node) => node.uid) : Object.keys(nodes);
        const changesForOperation = nodesPayload[operation];
        if (changesForOperation) {
            changesForOperation.push(...nodeUids);
        } else {
            nodesPayload[operation] = nodeUids;
        }
    }

    /**
     * Add a change (addition, removal, etc.) to the payload for rule sets
     * @param operation - The operation to be performed on the rule sets (add, remove, replace, set)
     * @param ruleSets - The rule sets to be changed (can be a map of rule sets or an array of rule sets)
     */
    public addRuleSetsChange(operation: ChangeOperation, ruleSets: PcbRuleSetsMap | PcbRuleSet[] | string[]) {
        const ruleSetsPayload = this.payload.pcbLayoutRuleSets;
        const ruleSetUids = Array.isArray(ruleSets)
            ? ruleSets.map((ruleSet) => (typeof ruleSet === "string" ? ruleSet : ruleSet.uid))
            : Object.keys(ruleSets);
        const changesForOperation = ruleSetsPayload[operation];
        if (changesForOperation) {
            changesForOperation.push(...ruleSetUids);
        } else {
            ruleSetsPayload[operation] = ruleSetUids;
        }
    }

    /**
     * Recalculate the DRC results based on the collected changes, and update the store with the results.
     *
     * If this is the first run, the DRC validators are set based on the feature flags,
     * and we subscribe to the feature flags to update the validators when they change.
     *
     * The collected changes are cleaned up before sending them to the DRC service to
     * avoid sending unnecessary data.
     */
    public async recalculate() {
        // Cleanup the payload before sending it to the DRC service
        // Don't send empty payloads
        const cleanedPayload = this.cleanupPayload();
        if (!cleanedPayload) {
            return;
        }

        const documentChange: DocumentSubCollectionChange = {
            kind: "subCollection",
            payload: cleanedPayload,
        };

        const inputs = await this.prepareInputs(documentChange);
        if (!inputs) {
            return;
        }

        const neighborsOfRemovedNodes = this.pcbTracingGraph.updateGraph(inputs).neighborsOfRemovedNodes;

        // This adds to the document change payload, the uids of nodes that were graph-neighbors of
        // nodes removed from the tracing graph. The primary purpose of this is to ensure route segments
        // connected to a removed route segment via curved trace shape, are re-rendered/updated in the
        // canvas
        mutateDocumentChangeIncludeUpdateNeighbors(neighborsOfRemovedNodes, documentChange);

        // Updates the shape store ,
        this.pcbPrimitiveStore.applyChange(documentChange, inputs.pcbLayoutNodes, this.pcbTracingGraph);

        // PCB Connectivity
        this.pcbConnectivityGraph.updateGraph(inputs, this.logger);

        // Finally, compute and update DRC
        const validators = generateValidatorsFromFeatureFlag(
            this.featureFlagsStore.getState().drcEnabledCheckers ?? {},
        );
        const validatorOutputs = validators.flatMap((val) => val.checkForProblems(inputs));
        const foundProblems = validatorOutputs.filter((r) => !r.error).flatMap((r) => r.foundProblems);
        const foundProblemsMap = Object.fromEntries(foundProblems.map((r) => [r.key, r]));
        const errors = Object.fromEntries(
            validatorOutputs.filter((r) => r.error).map((r) => [r.problemTypeKey, r.error as string]),
        );

        useProblemsStore.getState().updateDrcResults(foundProblemsMap, errors, validators);
    }

    /** Cleanup the payload before sending it to the DRC service */
    private cleanupPayload() {
        const finalPayload: DocumentSubCollectionChange["payload"] = {};

        const {pcbLayoutNodes, pcbLayoutRuleSets} = this.payload;

        if (!isEmpty(pcbLayoutNodes)) {
            const cleanedNodes = this.cleanupPayloadInternal(pcbLayoutNodes);
            if (cleanedNodes) {
                finalPayload.pcbLayoutNodes = cleanedNodes;
            }
        }

        if (!isEmpty(pcbLayoutRuleSets)) {
            const cleanedRuleSets = this.cleanupPayloadInternal(pcbLayoutRuleSets);
            if (cleanedRuleSets) {
                finalPayload.pcbLayoutRuleSets = cleanedRuleSets;
            }
        }

        if (isEmpty(finalPayload)) {
            return undefined;
        }

        return finalPayload;
    }

    /**
     * Cleanup the payload for a specific sub-collection:
     * * Remove duplicates
     * * Remove items that are both added and removed, and put them in the `replace` list
     * * Remove empty operations
     */
    private cleanupPayloadInternal<T extends DocumentChangePayload[keyof DocumentChangePayload]>(originalPayload: T) {
        const finalPayload: T = {} as T;

        for (const operation of Object.keys(originalPayload) as ChangeOperation[]) {
            const uids = originalPayload[operation];
            if (uids?.length) {
                const unique = Array.from(new Set(uids));
                if (unique.length) {
                    finalPayload[operation] = unique;
                }
            }
        }

        // If a uid appears in `add` and `remove`, it means it was updated
        // We should remove it from both lists and put it in the `set` list
        const added = finalPayload?.add;
        const removed = finalPayload?.remove;
        if (added?.length && removed?.length) {
            const updated = added.filter((node) => removed.includes(node));
            if (updated.length) {
                (finalPayload.replace ??= []).push(...updated);
                finalPayload.add = added.filter((node) => !updated.includes(node));
                finalPayload.remove = removed.filter((node) => !updated.includes(node));
                if (isEmpty(finalPayload.add)) delete finalPayload.add;
                if (isEmpty(finalPayload.remove)) delete finalPayload.remove;
            }
        }

        // HACK: Ensure that the nodes that we are adding and replacing do exist in the tree
        // Ideally, we should never need to do this since the changes accumulated in by DrcRecalculator
        // should be in sync with the document tree
        const allNodes = this.documentService.snapshot().pcbLayoutNodes;
        const operationsToCheck = ["add", "replace", "set"] as const;
        for (const operation of operationsToCheck) {
            const uids = finalPayload[operation];
            if (uids?.length) {
                finalPayload[operation] = uids.filter((nodeUid) => allNodes[nodeUid]);
            }
        }

        if (isEmpty(finalPayload)) {
            return undefined;
        }

        return finalPayload;
    }

    /**
     * Prepare the inputs for the DRC calculation
     */
    private async prepareInputs(change: DocumentChange | undefined): Promise<(IDrcInputs & UpdateGraphInputs) | null> {
        const reduxStore = this.reduxStoreService.getStore();
        const document = reduxStore.getState().document;
        if (!document) {
            return null;
        }

        // Prevent expensive DRC calculations if nothing has changed, unless forced
        const bakedNodesChanges = change?.kind === "subCollection" ? change?.payload.pcbLayoutNodes : undefined;
        const globalRulesChange = change?.kind === "subCollection" ? change?.payload.pcbLayoutRuleSets : undefined;
        if (isEmpty(globalRulesChange) && isEmpty(bakedNodesChanges)) {
            return null;
        }

        const elements = document.elements;
        const pcbLayoutRuleSets = document.pcbLayoutRuleSets;
        const pcbLayoutNodes = this.documentService.snapshot().pcbLayoutNodes;
        const allNetNodes = getNodesOfType(pcbLayoutNodes, PcbNodeTypes.net);
        const netMap = Object.fromEntries(allNetNodes.map((node) => [node.uid, node.terminals || {}])) as INetHashMap;
        const shapesMap = this.pcbPrimitiveStore.getFillShapesMap();
        const clipperShapeFactory = await new AsyncClipperShapeFactory().load();

        const nodeUidList = this.pcbPrimitiveStore.getAllNodeUidsWithShapes();
        const nodeShapesMap = this.pcbPrimitiveStore.getAllNodeShapesMap();
        const containerMap = this.pcbPrimitiveStore.getAllContainersMap();

        return {
            documentUid: document.uid,
            documentName: document.name,
            documentDescription: document.description,
            pcbLayoutNodes,
            elements,
            pcbLayoutRuleSets,
            netMap,

            nodeUidList,
            nodeShapesMap,
            containerMap,

            clipperShapeFactory,
            shapesMap,
            bakedNodesChanges,
            pcbConnectivityGraph: this.pcbConnectivityGraph,
            pcbTracingGraph: this.pcbTracingGraph,
        };
    }
}
