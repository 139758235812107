import {combineEpics, createEpicMiddleware} from "redux-observable";

import {getActiveServicesContainerSafelyFromRoot} from "../../injection/singleton";
import {
    makeGenerateActionRecordsEpic,
    makePatchHandlersEpic,
    saveDocumentConfigsEpic,
    saveDocumentEpic,
} from "../epicCreators/document";
import documentPatchManager from "../reducers/document/DocumentPatchManager";
import {publishPartResultNotifier, updatePartResultNotifier} from "../reducers/part/epics";

export const rootEpic = combineEpics(
    // Part related epics
    publishPartResultNotifier,
    updatePartResultNotifier,
    // Generate action records epic
    saveDocumentEpic,
    saveDocumentConfigsEpic,
    makePatchHandlersEpic(documentPatchManager),
    makeGenerateActionRecordsEpic(),
);

export const epicMiddleware = createEpicMiddleware({
    dependencies: {
        getContainer: getActiveServicesContainerSafelyFromRoot,
    },
});
