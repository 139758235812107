import {EditorModes} from "@buildwithflux/core";
import {AnyPcbNode, AutoLayoutIterationClientData, PcbNodesMap} from "@buildwithflux/models";

import {createActionRecordAction} from "../../../../../helpers/actionCreator";
import {AppThunkAction} from "../../../../../store";

export const applyAutoLayoutIteration = createActionRecordAction(
    "applyAutoLayoutIteration",
    (allNodes: PcbNodesMap<AnyPcbNode>, iteration: AutoLayoutIterationClientData, editorMode: EditorModes) => {
        return {
            payload: {
                allNodes,
                iteration,
                canUndo: true,
                inPcbEditor: editorMode === EditorModes.pcb,
                updatesDocumentTimestamp: true,
                shouldGenerateActionRecord: true,
            },
        };
    },
    "applied auto-layout",
);

export function applyAutoLayoutIterationThunk(
    allNodes: PcbNodesMap<AnyPcbNode>,
    iteration: AutoLayoutIterationClientData,
): AppThunkAction<void> {
    return (dispatch, getState, services) => {
        const {editorMode} = services.usePersistedDocumentUiStore.getState();
        dispatch(applyAutoLayoutIteration(allNodes, iteration, editorMode));
    };
}
