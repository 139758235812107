import {
    countPatchChanges,
    DocumentExceededMaximumFirestoreDepthError,
    IDocumentData,
    UnknownError,
    WriteDocumentConfigError,
} from "@buildwithflux/core";
import {areWeInStorybook, isDevEnv} from "@buildwithflux/shared";
import {AnyAction} from "@reduxjs/toolkit";
import {batchActions} from "redux-batched-actions";
import {StateObservable} from "redux-observable";
import {from, iif, merge, Observable, of} from "rxjs";
import {
    catchError,
    debounceTime,
    delay,
    filter,
    map,
    mapTo,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from "rxjs/operators";

import {useFeatureFlags} from "../../modules/feature_flags/hooks";
import {queueActionRecord as queueActionRecordWrite} from "../../modules/storage_engine/common/ActionRecordHelpers";
import {FluxLogger} from "../../modules/storage_engine/connectors/LogConnector";
import {DocumentStatusStates} from "../../redux/reducers/app";
import {
    enqueueNotification,
    removeNotification,
    setDocumentStatus,
    setIsReconnecting,
    toggleEditorBackDrop,
} from "../../redux/reducers/app/actions";
import {documentBatchActionTypes, setDocumentConfigs, writeDocument} from "../../redux/reducers/document/actions";
import {isDocumentReduxAction} from "../../redux/reducers/document/actions.types";
import DocumentPatchManager from "../../redux/reducers/document/DocumentPatchManager";
import R from "../../resources/Namespace";
import type {IApplicationState} from "../../state";

import {createActionDiscriminator, enqueueSyncErrorNotification, type EpicDependencies} from "./helpers";
import {
    bakePcbNodes,
    maybeRefetchSubDocumentData,
    processUndoRedo,
    runtimeChecks,
    syncPatchesWithWebWorkers,
} from "./sideEffects";

const isDocumentDepthError = (error: string) => {
    // Context: there is a cryptic firebase error that returns something along the lines of "Property x contains an invalid nested entitiy"
    // What this translates to is: this object is too deeply nested (i.e. depth > 20) to be saved.
    const isDepthError = error.includes("contains an invalid nested entity");
    if (isDepthError) {
        FluxLogger.captureError(new DocumentExceededMaximumFirestoreDepthError());
    }
    return isDepthError;
};

/**
 * This epic handles requests to set document configs on a per-user basis: view
 * controls and zoom-positions. Unlike the saveDocumentEpic, if this request
 * fails we are not currently triggering a network error (although we might want
 * to in the future).
 *
 * The entire configs collection is resaved without diffing. We expect this to
 * fast because the number of non-anonymous viewers of any document is small.
 *
 * NOTE: saveDocumentEpic will also save configs because of default
 * documentSubcollectionFields
 */
export const saveDocumentConfigsEpic = (
    action$: Observable<AnyAction>,
    state$: StateObservable<IApplicationState>,
    {getContainer}: EpicDependencies,
) => {
    return action$.pipe(
        filter(createActionDiscriminator(setDocumentConfigs)),
        filter(() => !(useFeatureFlags.getState().disableAutoSave ?? false)),
        debounceTime(R.behaviors.storage.writeMaxWait),
        switchMap((_action) => {
            const {documentStorage} = getContainer();

            const documentState = state$.value.document;
            if (!documentState) return [];
            const saveStartInMs = Date.now();
            documentStorage
                .saveConfigs(documentState)
                // eslint-disable-next-line no-console
                .then(() => isDevEnv() && console.info(`FLUX-PERF: save configs took ${Date.now() - saveStartInMs}ms`))
                .catch((error) =>
                    FluxLogger.captureError(
                        new WriteDocumentConfigError(`Failed to write document configs: ${JSON.stringify(error)}`),
                    ),
                );
            return [];
        }),
    );
};

export const saveDocumentEpic = (
    action$: Observable<AnyAction>,
    state$: StateObservable<IApplicationState>,
    {getContainer}: EpicDependencies,
) => {
    return action$.pipe(
        filter(createActionDiscriminator(writeDocument)),
        filter(
            (action) => !(useFeatureFlags.getState().disableAutoSave ?? false) || action.payload.overrideFeatureFlag,
        ),
        // NOTE: we debounce saves here instead of in
        // makeGenerateActionRecordsEpic because document writes may be dropped
        // for the next snapshot while should always save action records, which
        // are essentially diffs
        // NOTE: this delay has implications for our consistency model: we don't currently do optimistic concurrency
        //  locking or versioning on document writes, so part of the reason to use a large debounce time here is to
        //  provide more time for conflicting state to be locally merged via the action records mechanism.
        debounceTime(R.behaviors.storage.writeMaxWait),
        // NOTE: switchMap will drop previous observables for later observables,
        // but the firestore query is not cancelled
        switchMap((action) => {
            const {documentStorage} = getContainer();

            const documentState = action.payload.documentState as IDocumentData;

            const saveStartInMs = Date.now();
            return from(documentStorage.saveDocumentData(documentState)).pipe(
                mergeMap(() => {
                    if (isDevEnv()) {
                        // eslint-disable-next-line no-console
                        console.info(`FLUX-PERF: saveDocumentData took ${Date.now() - saveStartInMs}ms`);
                    }

                    const actions: AnyAction[] = [removeNotification("network_error")];

                    actions.push(setDocumentStatus(DocumentStatusStates.synced, new Date().getTime()));
                    if (!!state$.value && !(state$.value as IApplicationState).app.showEditorBackdrop) {
                        actions.push(toggleEditorBackDrop(false));
                    }

                    if (!!state$.value && !(state$.value as IApplicationState).app.isReconnecting) {
                        actions.push(setIsReconnecting(false));
                    }

                    return of(batchActions(actions, documentBatchActionTypes.WRITE_DOCUMENT_FINISHED));
                }),
                catchError((error: Error) => {
                    // QUESTION: why dynamic import here?
                    const NetworkErrorSnackbar =
                        require("../../components/common/components/Snackbars/NetworkError").default;
                    const SavingErrorSnackbar =
                        require("../../components/common/components/Snackbars/SavingError").default;
                    FluxLogger.captureError(
                        new UnknownError(`Error in saveDocumentEpic processing action ${action.type}: ${error}`, error),
                    );
                    return merge(
                        of(
                            toggleEditorBackDrop(true),
                            setDocumentStatus(DocumentStatusStates.error, new Date().getTime(), "Failed to save"),
                        ),
                        iif(
                            () => {
                                const currentState = state$.value as IApplicationState;
                                return (
                                    currentState.app.notifications.length > 0 &&
                                    !!currentState.app.notifications.find((notification) => {
                                        return !notification.dismiss && notification.key === "network_error";
                                    })
                                );
                            },
                            of(setIsReconnecting(false)),
                            merge(
                                of(
                                    enqueueNotification("", "project", {
                                        preventDuplicate: true,
                                        autoHideDuration: null,
                                        key: "network_error",
                                        content: isDocumentDepthError(error.message)
                                            ? SavingErrorSnackbar
                                            : NetworkErrorSnackbar,
                                    }),
                                ),
                                of(toggleEditorBackDrop(true)),
                            ),
                        ),
                    );
                }),
            );
        }),
    );
};

/**
 * NOTE: this is also the most frequent codepath into saveDocumentEpic.
 */
export const makeGenerateActionRecordsEpic = () => {
    return (
        action$: Observable<AnyAction>,
        state$: StateObservable<IApplicationState>,
        {getContainer}: EpicDependencies,
    ) => {
        const status =
            useFeatureFlags.getState().disableAutoSave ?? false
                ? DocumentStatusStates.syncNeeded
                : DocumentStatusStates.syncing;
        const services = getContainer();
        const {reduxStoreService, currentUserService, reduxSolderAdapter} = services;
        return action$.pipe(
            // See also safety check in makePatchHandlersEpic
            filter(
                (action: AnyAction) =>
                    isDocumentReduxAction(action) && !!action.payload.shouldGenerateActionRecord && !areWeInStorybook(),
            ),
            // Set the "Saving..." status
            withLatestFrom(state$),
            filter(([_action, appState]) => {
                const latestPatch = appState.document?.latestPatch;
                const latestPatchSize = latestPatch ? countPatchChanges(latestPatch) : 0;
                return latestPatchSize > 0;
            }),
            tap(([action, appState]) => {
                const currentDocumentState = appState.document;
                // Only update document status to `Saving` when we are NOT using solder to persist
                // the document and history (i.e. events)
                // TODO: should do this somewhere in DocumentService
                if (currentDocumentState && !reduxSolderAdapter.shouldUseSolder(currentDocumentState, action)) {
                    reduxStoreService.getStore().dispatch(setDocumentStatus(status, new Date().getTime()));
                }
            }),
            delay(200), // 200ms is the time needed to move this after rendering, according to testing
            mergeMap((result) => {
                const [action, newRootState] = result;
                const {
                    document: newDocumentState,
                    auth: {currentUserHasEditPermission, currentUserHasCommentingPermission},
                } = newRootState;
                const currentUser = currentUserService.getCurrentUser();

                try {
                    if (!currentUser) {
                        throw new Error(`No current user!`);
                    }
                    if (!newDocumentState) {
                        throw new Error(`No document state!`);
                    }
                    // If we detect that we should use solder system to persist the document and
                    // history (i.e. events), dont do anything here.
                    if (reduxSolderAdapter.shouldUseSolder(newDocumentState, action)) return [];

                    // User must have at least commenting permission to write action records
                    if (!(currentUserHasEditPermission || currentUserHasCommentingPermission)) {
                        throw new Error(`User ${currentUser.handle} does not have "edit" or "commenting" permissions`);
                    }

                    // NOTE: useful for debugging hard to reproduce issues
                    if (useFeatureFlags.getState().disableActionRecordWrites ?? false) {
                        return of(setDocumentStatus(DocumentStatusStates.synced, Date.now()));
                    }

                    queueActionRecordWrite(currentUser, action, newDocumentState, services);

                    // QUESTION: what if a user has commenting permission but not edit permission? no save?
                    if (currentUserHasEditPermission) {
                        return of(writeDocument(newDocumentState));
                    }
                    return of(setDocumentStatus(DocumentStatusStates.synced, Date.now()));
                } catch (error) {
                    if (error instanceof Error) {
                        error.message = `Error trying to generate an action record from action ${action.type}: ${error.message}`;
                        error.cause = error;
                    }
                    FluxLogger.captureError(error);

                    // QUESTOIN: is it always a good idea to only attempt the
                    // save if the action record succeeds?
                    return of(setDocumentStatus(DocumentStatusStates.error, new Date().getTime(), "Failed to save"));
                }
            }),
        );
    };
};

export const makePatchHandlersEpic = (documentPatchManager: typeof DocumentPatchManager) => {
    // NOTE: use our own var instead of pairwise() because we want to see
    // identical states when an action has no effect on state
    let lastSeenState: IApplicationState | undefined = undefined;
    return (
        action$: Observable<AnyAction>,
        state$: StateObservable<IApplicationState>,
        {getContainer}: EpicDependencies,
    ) => {
        const services = getContainer();

        return action$.pipe(
            //
            // We filter down to cases where the following criteria hold:
            // 1) the latest patch on the document has actually changed in the
            //    observed transition, and
            // 2) the latest patch is non empty
            //
            filter((_action) => {
                const currentState = state$.value;
                const lastPatch = lastSeenState?.document?.latestPatch;
                lastSeenState = currentState;
                const currentPatch = currentState.document?.latestPatch;
                const currentPatchSize = currentPatch ? countPatchChanges(currentPatch) : 0;
                return currentPatchSize > 0 && currentPatch?.uid !== lastPatch?.uid;
            }),
            //
            // Extract the current data for downstream handlers
            //
            map((action) => {
                const currentState = state$.value;
                // TS can't tell us based on the previous filter that this condition will hold, so we need
                // to check it again.
                if (currentState.document?.latestPatch) {
                    return {action, patch: currentState.document.latestPatch, document: currentState.document};
                }
                throw new Error(
                    `runtime logic error: latestPatch was supposed to be non-null bc of filter, but isnt.  can't proceed.`,
                );
            }),
            //
            // Runtime safety check to go with makeGenerateActionRecordsEpic
            //
            tap(runtimeChecks),
            //
            // Add the forward and reverse patches to the undo and redo stacks
            // We only do so when:
            // 1. The action is undoable - with `canUndo` flag
            // 2. The action is NOT from remote user - we dont wanna undo any changes from other users
            //
            tap(processUndoRedo(documentPatchManager)),
            //
            // Patch web workers state with the latest patch.
            // NOTE: this needs to happen before baking!
            //
            tap(syncPatchesWithWebWorkers(services)),
            //
            // Handle changes that require rebaking PCB nodes
            //
            tap(bakePcbNodes(services)),
            //
            // Handle changes that require refetching sub-document data
            //
            tap(maybeRefetchSubDocumentData(services)),
            //
            // Return a NOOP action to avoid dispatching the above action
            // QUESTION: is there a better way?
            //
            mapTo({type: "NOOP"}),
            /**
             * We have to make sure we actually catch any error here, otherwise
             * the observable will shut down and we won't process any further
             * actions!
             * */
            catchError((error) => {
                const message = "Error syncing data!";
                // NOTE: prepending the shared message allows easy search in Sentry
                error.message = message + " " + error.message;
                FluxLogger.captureError(error);
                return of(
                    enqueueSyncErrorNotification(
                        message,
                        getContainer().useFeatureFlagsStore.getState().showSyncErrors ?? true,
                    ),
                );
            }),
        );
    };
};
