import {findExampleProjectWithTag, OnboardingProject} from "@buildwithflux/constants";
import {cloneExampleProjectRoute, getProfileUrl, Origin} from "@buildwithflux/core";
import {isCypressTestUserAgent} from "@buildwithflux/shared";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Link from "@material-ui/core/Link";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CreditCard from "@material-ui/icons/CreditCard";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import cn from "classnames";
import React, {useCallback, useEffect, useState} from "react";
import ReactPlayer from "react-player/lazy";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";

import {useFluxServices} from "../../../injection/hooks";
import {useCurrentUser} from "../../../modules/auth/state/currentUser";
import {useSetPrivateMetadata, useUserViewedAutoLayoutAnnouncementState} from "../../../modules/auth/state/user";
import {selectAllOnboardingProjectSets, useFeatureFlags} from "../../../modules/feature_flags/hooks";
import {SurfaceBasedTrackingEvents} from "../../../modules/storage_engine/common/SurfaceBasedTrackingEvents";
import {closeDialog as closeDialogAction, DialogType, openDialog} from "../../../redux/reducers/dialogs";
import selectors from "../../../redux/selectors";
import AutoLayoutHeroImage from "../../../resources/assets/announcements/2024-12-auto-layout.jpg";
import YoutubePlayButtonImage from "../../../resources/assets/YouTube_full-color_icon_2017.svg";
import externalUrls from "../../../resources/ExternalUrls";
import {ClosableDialogTitle, FluxDialog} from "../../common/components/dialog/FluxDialog";
import {CopilotIcon} from "../../common/components/Icons";
import {signUp} from "../../navigation_bar/common/helpers";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        dialogContent: {
            maxHeight: "767px",
            flex: "1 1 auto",
            boxSizing: "content-box",
            paddingTop: 0,
            overflowY: "auto",
        },
        dialogTitle: {
            color: "#fff",
        },
        banner: {
            overflow: "hidden",
            borderRadius: "8px",
            marginBottom: "32px",
        },
        content: {},
        contentRow: {
            display: "flex",
            flexDirection: "row",
            width: "552px",
            marginBottom: "24px",
            alignItems: "center",
            height: "100%",
            "&:last-child": {
                marginBottom: "16px",
            },
        },
        contentIcon: {
            fontSize: "35px",
            marginRight: "16px",
        },
        contentText: {
            display: "flex",
            flexDirection: "column",

            "& p:first-child": {
                height: "24px",
                verticalAlign: "middle",
                lineHeight: "24px",
                marginBottom: "4px",
            },
            "& p:not(first-child)": {
                marginBottom: "0px",
                verticalAlign: "middle",
                lineHeight: "20px",
            },
        },
        contentAction: {
            paddingRight: "24px",
        },
        plansAndPayments: {
            color: "#fff",
            textDecoration: "underline",
            cursor: "pointer",
        },
    }),
);

export type AutoLayoutAnnouncementDialogComponentProps = {
    /**
     * - "announcement" is when this dialog is opened as an announcement to users, should only show once after user dismissed it
     * - "info" is when this dialog is opened when user clicks the "info" icon from the auto layout fab
     */
    serveAs: "announcement" | "info";
};

const heroImage = (
    <img src={AutoLayoutHeroImage} width={"100%"} height={"100%"} alt="Flux Auto-Layout Video Tutorial" />
);
const playButton = <img src={YoutubePlayButtonImage} width={"112px"} style={{position: "absolute"}} alt="Play Video" />;

const reactPlayerConfig = {youtube: {playerVars: {showinfo: 1}}};

function AutoLayoutAnnouncementDialogComponent(props: AutoLayoutAnnouncementDialogComponentProps) {
    const {analyticsStorage} = useFluxServices();
    const classes = useStyles();
    // If not serve as announcement, always open the dialog, and we rely on
    // redux state to manage this dialog
    const [open, setOpen] = useState<boolean>(props.serveAs !== "announcement");

    const history = useHistory();
    const documentOrganizationOwner = useSelector(selectors.documentMeta.selectDocumentOrganizationOwner);
    const documentEnterpriseOwner = useSelector(
        selectors.documentMeta.selectDocumentEnterpriseOwnerWithDefaultOrganization,
    );

    // TODO: Clean-up when not needed
    const forceShowAnnouncement = window.location.search.includes("showAutoLayoutAnnouncement");

    const [exampleProject, setExampleProject] = useState<OnboardingProject | undefined>(undefined);
    const allExampleProjects = useFeatureFlags(selectAllOnboardingProjectSets);

    useEffect(() => {
        const onboardingProject = findExampleProjectWithTag(allExampleProjects, "AutoLayoutDialogExample");
        if (onboardingProject) {
            setExampleProject(onboardingProject);
        }
    }, [allExampleProjects]);

    const currentUser = useCurrentUser();
    const dispatch = useDispatch();
    const setPrivateMetadata = useSetPrivateMetadata();
    const viewedAnnouncementState = useUserViewedAutoLayoutAnnouncementState();

    // If serve as an announcement, upon first render, open the dialog and send analytic
    useEffect(() => {
        if (props.serveAs === "announcement") {
            if ((!viewedAnnouncementState && !isCypressTestUserAgent()) || forceShowAnnouncement) {
                setOpen(true);
                void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
                    content_type: "autoLayoutAnnouncementDialog",
                    action: "open",
                });
            }
        }
    }, [analyticsStorage, viewedAnnouncementState, forceShowAnnouncement]);

    const closeDialog = useCallback(() => {
        if (props.serveAs === "announcement") {
            setOpen(false);
            // Store this metadata, so that users only see this dialog once if
            // they close it
            void setPrivateMetadata({
                announcement: {
                    autoLayoutViewed: true,
                },
            });
        } else {
            dispatch(closeDialogAction(DialogType.AUTO_LAYOUT_ANNOUNCEMENT));
        }
    }, [props.serveAs, setPrivateMetadata, dispatch]);

    // We want to track closing dialog separately and hence two separate functions
    const onClickBackdrop = useCallback(() => {
        closeDialog();

        void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
            content_type: "autoLayoutAnnouncementDialog",
            action: "close_dialog_by_backdrop",
            serveAs: props.serveAs,
        });
    }, [analyticsStorage, props.serveAs, closeDialog]);

    const onClickX = useCallback(() => {
        closeDialog();

        void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
            content_type: "autoLayoutAnnouncementDialog",
            action: "close_dialog_by_x",
            serveAs: props.serveAs,
        });
    }, [analyticsStorage, props.serveAs, closeDialog]);

    const handleTryIt = useCallback(() => {
        if (currentUser?.isAnonymous) {
            signUp();
            return;
        }

        if (!exampleProject) {
            // FIXME: Log an error and/or say sorry to the user.
            return;
        }

        const route = cloneExampleProjectRoute(exampleProject.uid, Origin.AutoLayoutAnnouncement);

        if (isCypressTestUserAgent()) {
            history.push(route);
        } else {
            window.open(route, "_blank");
        }

        closeDialog();
        void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
            content_type: "autoLayoutAnnouncementDialog",
            action: "try_it",
            serveAs: props.serveAs,
        });
    }, [analyticsStorage, exampleProject, closeDialog, history, props]);

    const handleLearnMore = useCallback(() => {
        // NOTE: Not closing the dialog
        window.open(externalUrls.documentation.auto_layout_tutorial, "_blank");

        void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
            content_type: "autoLayoutAnnouncementDialog",
            action: "learn_more",
            serveAs: props.serveAs,
        });
    }, [analyticsStorage, props.serveAs]);

    /*
     QUESTION: Does it actually make sense to take the uer to the org plan pages from this dialog, ever?
     */
    const onClickPlansAndPayments = useCallback(() => {
        if (documentOrganizationOwner) {
            // If document is owned by an org, navigate to org's settings page to manage subscription
            window.open(getProfileUrl(documentOrganizationOwner, {subRoute: "settings"}), "_blank");
            void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
                content_type: "autoLayoutAnnouncementDialog",
                action: "plans_and_payments_org",
                serveAs: props.serveAs,
            });
        } else if (documentEnterpriseOwner) {
            // If document is owned by an enterprise, navigate to its default org's settings page to manage subscription
            window.open(
                getProfileUrl(documentEnterpriseOwner.defaultOrganization, documentEnterpriseOwner, {
                    subRoute: "settings",
                }),
                "_blank",
            );
            void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
                content_type: "autoLayoutAnnouncementDialog",
                action: "plans_and_payments_enterprise",
                serveAs: props.serveAs,
            });
        } else if (currentUser) {
            // If document is owned by a user, pop the subscription management modal
            dispatch(openDialog(DialogType.USER_PLANS_AND_PAYMENTS));

            void analyticsStorage.logEvent(SurfaceBasedTrackingEvents.onboardingUx, {
                content_type: "autoLayoutAnnouncementDialog",
                action: "plans_and_payments_user",
                serveAs: props.serveAs,
            });
        }
    }, [currentUser, documentOrganizationOwner, documentEnterpriseOwner, dispatch, analyticsStorage, props.serveAs]);

    const fullScreen = useMediaQuery("(max-width:1023px)");

    const [isPlaying, setIsPlaying] = useState(false);

    const onClickPreview = useCallback(() => {
        setIsPlaying(true);
    }, []);

    return (
        <FluxDialog onClose={onClickBackdrop} fullScreen={fullScreen} open={open}>
            <ClosableDialogTitle
                id="autolayout-dialog-title"
                className={classes.dialogTitle}
                whiteCloseIcon
                onClose={onClickX}
            >
                Introducing AI Auto-Layout Beta!
            </ClosableDialogTitle>
            <DialogContent className={cn(classes.dialogContent)}>
                <ReactPlayer
                    playing={isPlaying}
                    className={classes.banner}
                    height={"331px"}
                    width={"552px"}
                    light
                    fallback={heroImage}
                    onClickPreview={onClickPreview}
                    config={reactPlayerConfig}
                    url={externalUrls.documentation.auto_layout_tutorial_video}
                    playIcon={playButton}
                />

                <Box className={classes.content}>
                    <Box className={classes.contentRow}>
                        <CopilotIcon className={classes.contentIcon} />
                        <Box className={classes.contentText}>
                            <Typography variant="body1" component="p" gutterBottom>
                                Route with AI
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                Use Copilot to route your board for you! Copilot’s AI technology produces results that
                                actually make sense and take the busywork out of routing!
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.contentRow}>
                        <PlayCircleFilled className={classes.contentIcon} />
                        <Box className={classes.contentText}>
                            <Typography variant="body1" component="p" gutterBottom>
                                Just One Click
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                In the PCB mode of a project, just click the Auto-Layout button. Copilot will iterate on
                                solutions which improve the longer you wait. It’s best used on low to medium complexity
                                projects and can make mistakes.
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.contentRow}>
                        <CreditCard className={classes.contentIcon} />
                        <Box className={classes.contentText}>
                            <Typography variant="body1" component="p" gutterBottom>
                                Try For Free
                            </Typography>
                            <Typography variant="body2" component="p" gutterBottom>
                                Auto-Layout uses your free Copilot Credits which refresh each month. When those are used
                                up, instead of waiting, you can get more in{" "}
                                <Link className={classes.plansAndPayments} onClick={onClickPlansAndPayments}>
                                    Plans & Payments.
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions className={classes.contentAction}>
                <Button color="primary" size="medium" onClick={handleLearnMore}>
                    Learn more
                </Button>
                {props.serveAs === "announcement" ? (
                    <Button color="primary" size="medium" variant="contained" onClick={handleTryIt}>
                        Try it
                    </Button>
                ) : (
                    <Button color="primary" size="medium" onClick={onClickX}>
                        Close
                    </Button>
                )}
            </DialogActions>
        </FluxDialog>
    );
}

export const AutoLayoutAnnouncementDialog = React.memo(AutoLayoutAnnouncementDialogComponent);
