import {
    AnyPcbBakedNode,
    AnyPcbNode,
    AsyncClipperShapeFactory,
    GerberExporterError,
    PcbNodesMap,
    PcbPrimitiveStore,
    PcbTracingGraph,
} from "@buildwithflux/core";
import {IDocumentData, IElementsMap} from "@buildwithflux/models";
import {Logger} from "@buildwithflux/shared";
import type {DocumentService} from "@buildwithflux/solder-core";
import {createSelector} from "@reduxjs/toolkit";
import axios from "axios";
import {OptionsObject} from "notistack";

import {BillOfMaterialsExporter} from "../../../../../modules/data_portability/exporters/BillOfMaterials/BillOfMaterialsExporter";
import fluxToKicad from "../../../../../modules/data_portability/exporters/depreciated/Gerber/FluxToKicad";
import {PickAndPlaceExporter} from "../../../../../modules/data_portability/exporters/PickAndPlace/PickAndPlaceExporter";
import type {IApplicationState} from "../../../../../state";

const gerberExporterEndpoint = process.env.REACT_APP_GERBER_EXPORTER_URL;
const GERBER_PATH = "/gerber";
const NETLIST_PATH = "/netlist";

export const exportErrorMsg = "Oops! Flux couldn’t export this project. Please report this error to Flux.";

export const gerberErrorOptionsObject: OptionsObject = {
    preventDuplicate: true,
    variant: "error",
    autoHideDuration: 5000,
};

interface IGerberParams {
    layers: string[];
    kicad: string;
    filename: string;
}

export const selectIsBomAvailable = createSelector(
    (state: IApplicationState) => state.document?.elements ?? {},
    (elements: IElementsMap) => {
        return BillOfMaterialsExporter.hasThingsToExport(elements);
    },
);

// NOTE: see JEP30Exporter to see why this data is needed
export const selectMissingJEP30ExportData = createSelector(
    (state: IApplicationState) => state.document?.properties,
    (properties) => {
        const missing: string[] = [];
        const propertyNames = new Set(
            Object.values(properties ?? {})
                .filter((property) => !!property.value)
                .map((property) => property.name),
        );
        if (!propertyNames.has("Manufacturer Part Number")) {
            missing.push("Manufacturer Part Number");
        }
        if (!propertyNames.has("Manufacturer Name")) {
            missing.push("Manufacturer Name");
        }
        if (!propertyNames.has("Datasheet URL")) {
            missing.push("Datasheet URL");
        }
        return missing;
    },
);

export const selectIsJEP30ExportDataAvailable = createSelector(selectMissingJEP30ExportData, (jep30ExportData) => {
    return jep30ExportData.length === 0;
});

export const selectPickAndPlaceDataAvailable = createSelector(
    (state: IApplicationState) => state.document?.pcbLayoutNodes ?? {},
    (pcbLayoutNodes: PcbNodesMap<AnyPcbNode>) => {
        return PickAndPlaceExporter.hasThingsToExport(pcbLayoutNodes);
    },
);

export function getActivePcbLayoutNodes(documentService: DocumentService) {
    const pcbLayoutNodesArray = Object.entries(documentService.snapshot().pcbLayoutNodes);
    if (pcbLayoutNodesArray.length) {
        const activePcbLayoutNodesArray = pcbLayoutNodesArray.filter(([_key, value]) => value.bakedRules?.active);
        return Object.fromEntries(activePcbLayoutNodesArray);
    }
}

export async function getGerberParams(
    documentData: IDocumentData,
    activePcbLayoutNodes: PcbNodesMap<AnyPcbBakedNode>,
    isUsingNewKicadToGerberExport: boolean,
    logger: Logger,
    pcbPrimitiveStore: PcbPrimitiveStore,
    tracingGraph: PcbTracingGraph,
) {
    // convert from Flux store format to a Kicad .kicad_pcb file
    // if errmsg is non-blank, then an error has occured while converting
    try {
        if (isUsingNewKicadToGerberExport) {
            const KiCadPcbExporter = await import(
                /* webpackChunkName: 'KicadPcbExporter' */ "../../../../../modules/data_portability/exporters/KicadPcbExporter"
            );
            const spillShapes = pcbPrimitiveStore.getSpillShapesMap();
            const fillShapes = pcbPrimitiveStore.getFillShapesMap();
            const converter = new KiCadPcbExporter.KiCadConverter(
                activePcbLayoutNodes,
                await new AsyncClipperShapeFactory().load(),
                logger,
                spillShapes,
                fillShapes,
                tracingGraph,
            );

            const {kicadString, layersForGerberExport} = converter.convert();
            // params to send to Gerber service
            return {
                layers: layersForGerberExport,
                kicad: kicadString,
                filename: documentData.slug,
            };
        } else {
            const {layers, kicadstr, errmsg} = fluxToKicad(JSON.stringify(activePcbLayoutNodes));

            if (errmsg.length) {
                throw new Error(errmsg);
            }

            // params to send to Gerber service
            return {
                layers,
                kicad: kicadstr,
                filename: documentData.slug,
            };
        }
    } catch (error) {
        throw new GerberExporterError("Gerber conversion error: " + ((error as Error)?.message ?? "Unknown error"));
    }
}

export async function getNetListResponse(gerberParams: IGerberParams) {
    try {
        const res = await axios.request({
            url: gerberExporterEndpoint + NETLIST_PATH,
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "blob",
            data: gerberParams,
        });

        if (!(res.data instanceof Blob)) {
            throw new Error(`Invalid net list response`);
        }

        return res.data;
    } catch (error) {
        throw new GerberExporterError(
            "Error returned from Netlist service: " + ((error as Error)?.message ?? "Unknown error"),
        );
    }
}

export async function getGerberResponse(gerberParams: IGerberParams) {
    try {
        const res = await axios.request({
            url: gerberExporterEndpoint + GERBER_PATH,
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            responseType: "blob",
            data: gerberParams,
        });

        if (!(res.data instanceof Blob)) {
            throw new Error(`Invalid gerber response`);
        }

        return res.data;
    } catch (error) {
        throw new GerberExporterError(
            "Error returned from Gerber service: " + ((error as Error)?.message ?? "Unknown error"),
        );
    }
}
