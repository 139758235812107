import {DeepPcbBoard} from "@buildwithflux/models";
import {Logger} from "@buildwithflux/shared";

import {AutoLayoutBoardFetcher} from "./AutoLayoutBoardFetcher";

export class DeepPcbBoardFetcher implements AutoLayoutBoardFetcher {
    constructor(private readonly logger: Logger) {}

    /* @inheritDoc */
    public async fetchBoard(boardLocation: string): Promise<DeepPcbBoard> {
        this.logger.info(`Fetching board from ${boardLocation}`);
        return fetch(boardLocation)
            .then((response) => {
                return response.json();
            })
            .then((board) => board as DeepPcbBoard);
    }
}
