import SvgIcon, {SvgIconProps} from "@material-ui/core/SvgIcon";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import LockIcon from "@material-ui/icons/Lock";
import MessageIcon from "@material-ui/icons/Message";
import PublicIcon from "@material-ui/icons/Public";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React, {ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from "react";

import {ReactComponent as alert} from "../../../resources/assets/icons/alert-octagon.svg";
import {ReactComponent as arrow} from "../../../resources/assets/icons/arrow.svg";
import {ReactComponent as codeCrossed} from "../../../resources/assets/icons/code-crossed.svg";
import {ReactComponent as copilotAccentedVisor} from "../../../resources/assets/icons/copilot-accented-visor.svg";
import {ReactComponent as copilot} from "../../../resources/assets/icons/copilot.svg";
import {ReactComponent as copy} from "../../../resources/assets/icons/copy.svg";
import {ReactComponent as cubeCrossed} from "../../../resources/assets/icons/cube-crossed.svg";
import {ReactComponent as cube} from "../../../resources/assets/icons/cube.svg";
import {ReactComponent as digikey} from "../../../resources/assets/icons/digikey.svg";
import {ReactComponent as factory} from "../../../resources/assets/icons/factory.svg";
import {ReactComponent as fluxIcon} from "../../../resources/assets/icons/flux-8x8.svg";
import {ReactComponent as layoutCrossed} from "../../../resources/assets/icons/layout-crossed.svg";
import {ReactComponent as lcsc} from "../../../resources/assets/icons/lcsc.svg";
import {ReactComponent as line} from "../../../resources/assets/icons/line.svg";
import {ReactComponent as memoryCrossed} from "../../../resources/assets/icons/memory-crossed.svg";
import {ReactComponent as mouser} from "../../../resources/assets/icons/mouser.svg";
import {ReactComponent as polygonsZonesViewDisabled} from "../../../resources/assets/icons/no-polygon.svg";
import {ReactComponent as zoneViewDisabled} from "../../../resources/assets/icons/no-zone.svg";
import {ReactComponent as padTypeSMD} from "../../../resources/assets/icons/pad-type-smd.svg";
import {ReactComponent as padTypeSTD} from "../../../resources/assets/icons/pad-type-std.svg";
import {ReactComponent as paste} from "../../../resources/assets/icons/paste.svg";
import {ReactComponent as photo} from "../../../resources/assets/icons/photo.svg";
import {ReactComponent as pin} from "../../../resources/assets/icons/pin.svg";
import {ReactComponent as privacyEnterpriseComment} from "../../../resources/assets/icons/privacy-enterprise-comment.svg";
import {ReactComponent as privacyEnterprisePrivate} from "../../../resources/assets/icons/privacy-enterprise-private.svg";
import {ReactComponent as privacyEnterpriseView} from "../../../resources/assets/icons/privacy-enterprise-view.svg";
import {ReactComponent as privacyOrganizationComment} from "../../../resources/assets/icons/privacy-organization-comment.svg";
import {ReactComponent as privacyOrganizationPrivate} from "../../../resources/assets/icons/privacy-organization-private.svg";
import {ReactComponent as privacyOrganizationView} from "../../../resources/assets/icons/privacy-organization-view.svg";
import {ReactComponent as project} from "../../../resources/assets/icons/project.svg";
import {ReactComponent as QrCode2} from "../../../resources/assets/icons/qr-code-2.svg";
import {ReactComponent as rocket} from "../../../resources/assets/icons/rocket.svg";
import {ReactComponent as route} from "../../../resources/assets/icons/route.svg";
import {ReactComponent as skull} from "../../../resources/assets/icons/skull.svg";
import {ReactComponent as terminals} from "../../../resources/assets/icons/terminals.svg";
import {ReactComponent as textFieldsOff} from "../../../resources/assets/icons/text-fields-off.svg";
import {ReactComponent as tracePair} from "../../../resources/assets/icons/trace-pair.svg";
import {ReactComponent as verical} from "../../../resources/assets/icons/verical.svg";
import {ReactComponent as visibilityMixed} from "../../../resources/assets/icons/visibility-mixed.svg";
import {ReactComponent as airWiresOff} from "../../../resources/assets/pcb/icons/airwires-off.svg";
import {ReactComponent as airWiresOn} from "../../../resources/assets/pcb/icons/airwires-on.svg";

type SvgIconComponent = ForwardRefExoticComponent<PropsWithoutRef<SvgIconProps> & RefAttributes<SVGSVGElement>>;

export const AirWiresOnIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={airWiresOn} ref={ref} />;
    }),
);

export const AirWiresOffIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={airWiresOff} ref={ref} />;
    }),
);

export const CubeIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={cube} ref={ref} />;
    }),
);

export const MpnIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={QrCode2} ref={ref} />;
    }),
);

export const CubeIconCrossed: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 18 18" {...props} component={cubeCrossed} ref={ref} />;
    }),
);

export const MemoryIconCrossed: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 18 18" {...props} component={memoryCrossed} ref={ref} />;
    }),
);

export const CodeIconCrossed: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 18 18" {...props} component={codeCrossed} ref={ref} />;
    }),
);

export const LayoutIconCrossed: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 18 18" {...props} component={layoutCrossed} ref={ref} />;
    }),
);

export const LineIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={line} ref={ref} />;
    }),
);

export const RouteIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={route} ref={ref} />;
    }),
);

export const TracePairIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={tracePair} ref={ref} />;
    }),
);

export const RocketIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={rocket} ref={ref} />;
    }),
);

export const CopyIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={copy} ref={ref} />;
    }),
);

export const PasteIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={paste} ref={ref} />;
    }),
);

export const CopilotIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={copilot} ref={ref} />;
    }),
);

export const CopilotAccentedIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={copilotAccentedVisor} ref={ref} />;
    }),
);

export const PhotoIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={photo} ref={ref} />;
    }),
);

export const ProjectIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={project} ref={ref} />;
    }),
);

export const FactoryIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 -900 960 960" {...props} component={factory} ref={ref} />;
    }),
);

export const EnterpriseIcon = BusinessIcon;
export const OrganizationIcon = GroupIcon;

export const PrivacyOrganizationEditIcon = OrganizationIcon;
export const PrivacyOrganizationPrivateIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={privacyOrganizationPrivate} ref={ref} />;
    }),
);
export const PrivacyOrganizationCommentIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={privacyOrganizationComment} ref={ref} />;
    }),
);
export const PrivacyOrganizationViewIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={privacyOrganizationView} ref={ref} />;
    }),
);

export const PrivacyEnterpriseEditIcon = EnterpriseIcon;
export const PrivacyEnterprisePrivateIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={privacyEnterprisePrivate} ref={ref} />;
    }),
);
export const PrivacyEnterpriseCommentIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={privacyEnterpriseComment} ref={ref} />;
    }),
);
export const PrivacyEnterpriseViewIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={privacyEnterpriseView} ref={ref} />;
    }),
);

export const PrivacyPrivateIcon = LockIcon;
export const PrivacyEditIcon = PublicIcon;
export const PrivacyCommentIcon = MessageIcon;
export const PrivacyViewIcon = VisibilityIcon;

export const ZoneViewDisabledIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={zoneViewDisabled} ref={ref} />;
    }),
);

export const PolygonsZonesViewDisabledIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 24 24" {...props} component={polygonsZonesViewDisabled} ref={ref} />;
    }),
);

export const TextFieldsOffIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={textFieldsOff} ref={ref} />;
    }),
);

export const PadTypeSMDIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={padTypeSMD} ref={ref} />;
    }),
);

export const PadTypeSTDIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={padTypeSTD} ref={ref} />;
    }),
);

export const FluxIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 12 12" {...props} component={fluxIcon} ref={ref} />;
    }),
);

export const VisibilityMixedIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={visibilityMixed} ref={ref} />;
    }),
);

export const PinIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={pin} ref={ref} />;
    }),
);

export const TerminalsIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon {...props} component={terminals} ref={ref} />;
    }),
);

export const SkullIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 16 16" {...props} component={skull} ref={ref} />;
    }),
);

export const AlertIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 16 16" {...props} component={alert} ref={ref} />;
    }),
);

const DigikeyIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 32 32" {...props} component={digikey} ref={ref} />;
    }),
);

const MouserIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 32 32" {...props} component={mouser} ref={ref} />;
    }),
);

const LCSCIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 32 32" {...props} component={lcsc} ref={ref} />;
    }),
);

const ArrowIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 32 32" {...props} component={arrow} ref={ref} />;
    }),
);

const VericalIcon: SvgIconComponent = React.memo(
    React.forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
        return <SvgIcon viewBox="0 0 197 189" {...props} component={verical} ref={ref} />;
    }),
);

export const distributorIcons: Record<string, SvgIconComponent> = {
    digikey: DigikeyIcon,
    mouser: MouserIcon,
    lcsc: LCSCIcon,
    arrow: ArrowIcon,
    verical: VericalIcon,
};
